import { baseVariables } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  align-items: center;

  .swiper-horizontal {
    width: 100%;
    height: auto;
  }
`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  display: flex !important; // override out-of-the-box swiper styles
  flex-direction: column;

  .swiper-pagination-horizontal {
    width: fit-content;
    justify-self: center;
    position: relative;
    bottom: 0;
    margin-top: 16px;

    > span {
      vertical-align: middle;
    }
  }

  .carousel-arrow-container {
    @media ${baseVariables.mediaQuery.md} {
      display: none !important;
    }

    justify-content: space-evenly;

    button {
      background-color: ${baseVariables.color.base20};
      position: relative;
      top: -25px;
    }

    .left-arrow {
      left: 20px;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    transition: transform 300ms;
  }

  .swiper-slide:not(.swiper-slide-active) {
    transform: scale(0.95);
  }

  .swiper-button-next {
    left: 89.5%;
    @media ${baseVariables.mediaQuery.lg} {
      left: 90%;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;

    @media ${baseVariables.mediaQuery.md} {
      position: absolute;
      top: 50%;
      display: flex;
      width: 40px;
      height: 40px;
      border: thin ${baseVariables.color.neutral40};
      background: ${baseVariables.color.base20};
      border-radius: 50px;

      :after {
        font-size: 12px;
        font-weight: bold;
        color: ${baseVariables.color.base10};
      }
    }
  }

  .swiper-button-prev {
    @media ${baseVariables.mediaQuery.md} {
      left: 5.5%;
    }

    @media ${baseVariables.mediaQuery.lg} {
      left: 6.5%;
    }
  }

  .swiper-pagination-bullet {
    background-color: ${baseVariables.color.base10};
  }
`;
