import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledComp = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${theme.colors.white};

  .heading {
    font-size: ${theme.fonts['fontL']};
    font-weight: ${theme.fonts['fontWeightMedium']};
    color: ${theme.colors['base-10']};
    line-height: ${theme.fonts['lineHeightM']};
    margin-bottom: ${toRem(10)};
  }

  .link {
    margin-left: ${toRem(20)};
    color: ${theme.colors['dark-gray-3']};
    span {
      display: none;
    }
  }

  &.memberCard {
    border-radius: ${toRem(14)};
    padding: ${toRem(12)} ${toRem(20)} ${toRem(10)};
    border: ${toRem(1)} solid ${theme.colors.gallery};
    &.no-pwd-view {
      margin-bottom: ${toRem(8)};
      padding: ${toRem(24)};
      flex-direction: column;
      text-align: center;
      .tagline {
        font-size: ${toRem(16)};
        line-height: ${toRem(20)};
        font-weight: ${theme.fonts.fontWeightMedium};
        margin-bottom: ${toRem(8)};
      }
      .wo-pwd .description {
        margin-bottom: ${toRem(16)};
      }
      @media ${theme.mediaQuery.tablet} {
        flex-direction: row;
        padding: ${toRem(16)};
        text-align: left;
        .tagline {
          margin-bottom: ${toRem(8)};
        }
        .wo-pwd .description {
          margin-bottom: 0;
        }
        .btn-section {
          margin-left: ${toRem(16)};
        }
      }
    }
    .flex-1 {
      flex: 1;

      .banner-image {
        height: ${toRem(24)};
        width: ${toRem(82.67)};
        margin-right: ${toRem(29.3)};
      }

      .heading {
        margin-bottom: ${toRem(6)};
        font-size: ${theme.fonts['fontXs']};
        line-height: ${theme.fonts['lineHeightXs']};
        letter-spacing: ${toRem(1.3)};
        color: ${theme.colors['dark-gray-3']};
        text-transform: uppercase;
      }

      .description {
        margin: 0;
        font-weight: normal;
        font-size: ${theme.fonts['fontXs']};
        color: ${theme.colors['dark-gray-3']};
        line-height: ${theme.fonts['lineHeightXs']};
      }

      .link {
        color: ${theme.colors.black};
      }
    }
  }

  &.miniCard {
    height: ${toRem(140)};
    padding: ${toRem(20)};
    border-radius: ${toRem(14)};
    border: ${toRem(1)} solid ${theme.colors['close-silver']};
    margin-bottom: ${toRem(20)};

    .description {
      margin: 0;
      font-weight: normal;
      font-size: ${theme.fonts['fontS']};
      color: ${theme.colors['dark-gray-3']};
      line-height: ${theme.fonts['lineHeightM']};
    }

    .flex-1 {
      flex: 1;
    }
  }

  &.bonvoyBanner {
    cursor: pointer;
    padding: ${toRem(48)} ${toRem(20)};
    background-color: ${theme.colors['base-10']};

    .heading {
      display: none;
    }

    .description {
      font-weight: normal;
      font-size: ${theme.fonts['fontS']};
      white-space: pre-line;
      color: ${theme.colors.white};
      margin-bottom: ${toRem(0)};
      line-height: ${theme.fonts['lineHeightM']};
    }

    .m-link-tertiary-button::after {
      padding: 0;
      font-size: ${toRem(19.5)};
    }

    .link {
      margin-left: ${toRem(30)};
      color: ${theme.colors.white};
    }

    .banner-image {
      width: ${toRem(60)};
      height: ${toRem(60)};
    }
  }

  &.travelInsurance {
    border-radius: ${toRem(14)};
    padding: ${toRem(14)} ${toRem(20)};
    margin-bottom: ${toRem(8)};
    border: ${toRem(1)} solid ${theme.colors.gallery};
    min-height: ${toRem(60)};
    .flex-1 {
      flex: 1;
      .heading {
        margin-bottom: ${toRem(2)};
        font-size: ${theme.fonts['fontS']};
        line-height: ${theme.fonts['lineHeightXs']};
      }
      .description {
        margin: 0;
        font-weight: normal;
        font-size: ${theme.fonts['fontXs']};
        color: ${theme.colors['dark-gray-3']};
        line-height: ${theme.fonts['lineHeightXs']};
      }
      .link {
        color: ${theme.colors.black};
      }
    }
  }

  &.travelInsuranceBanner {
    border-radius: ${toRem(14)};
    padding: ${toRem(32)} ${toRem(95)};
    min-height: ${toRem(60)};

    .flex-1 {
      flex: 1;
      .banner-image {
        width: ${toRem(173)};
        height: ${toRem(44)};
      }
      .heading {
        margin-bottom: ${toRem(4)};
        font-size: ${toRem(22)};
        font-family: ${theme.fontFamily.swiss};
        line-height: ${toRem(22)};
      }
      .description {
        margin: 0;
        font-weight: normal;
        font-size: ${toRem(14)};
        color: ${theme.colors['dark-gray-3']};
        line-height: ${toRem(20)};
      }
      .title-wrapper {
        padding-left: ${toRem(65)};
      }
    }

    .button {
      padding: ${toRem(8)} ${toRem(16)};
      font-size: ${toRem(13)};
      display: flex;
      align-items: center;
    }
  }

  &.redemptionAlert {
    background-color: transparent;
    .flex-1 {
      flex: 1;
      margin-bottom: ${toRem(32)};
      .icon-free-nights {
        height: ${toRem(42)};
        width: ${toRem(42)};
        border-radius: ${toRem(21)};
        background-color: ${theme.colors['accent-20']};
        margin-right: ${toRem(8)};
      }
      .icon-extra-free-nights:before {
        height: ${toRem(18.33)};
        width: ${toRem(18.33)};
        padding: ${toRem(9)} ${toRem(13)};
        color: ${theme.colors['base-20']};
      }
      .heading {
        margin-bottom: ${toRem(2)};
        font-size: ${theme.fonts['fontM']};
        line-height: ${theme.fonts['lineHeightS']};
      }
      .description {
        margin: 0;
        font-weight: normal;
        font-size: ${theme.fonts['fontS']};
        line-height: ${theme.fonts['lineHeightS']};
      }
    }
  }

  @media ${theme.mediaQuery.minDesktop} {
    &.memberCard {
      min-height: ${toRem(60)};
    }
  }

  @media ${theme.mediaQuery.tabletMax} {
    &.miniCard {
      height: ${toRem(194)};
      padding: ${toRem(30)};

      .banner-image {
        margin-bottom: ${toRem(25)};
      }

      .flex-1 {
        height: calc(100%);
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  @media ${theme.mediaQuery.allTablets} {
    &.miniCard {
      margin-bottom: ${toRem(30)};
    }
    &.travelInsuranceBanner {
      padding: ${toRem(32)};
      display: flex;
      .flex-1 {
        flex: 1;
        .heading {
          font-size: ${toRem(16)};
        }
        .description {
          font-size: ${toRem(12)};
        }
        .title-wrapper {
          padding: 0 0 0 ${toRem(45)};
        }
      }
    }
    .button {
      line-height: ${toRem(17)};
    }
  }

  @media ${theme.mediaQuery.allMobiles} {
    &.checkinBanner {
      flex-direction: column;
      text-align: center;
      grid-template-columns: 1fr;
      grid-template-rows: 0.5fr 1fr 0.2fr;
      justify-items: center;
      padding-right: ${toRem(0)};

      .description {
        text-align: center;
      }
      .title-wrapper {
        font-weight: ${theme.fonts['fontWeightRegular']};
      }
      .m-button-m {
        width: ${toRem(284)};
      }
    }
    div:first-child {
      margin: 0 auto;
    }
    section:nth-child(2) {
      display: grid;
    }
    &.bonvoyBanner {
      div:first-child {
        width: auto;
        margin-right: ${toRem(16)};
      }
    }
    &.miniCard {
      padding: ${toRem(30)};
      height: auto;
      min-height: ${toRem(140)};

      .banner-image {
        margin-bottom: ${toRem(25)};
      }
    }
    &.memberCard {
      padding: ${toRem(13)} ${toRem(24)} ${toRem(16)};
      .flex-1 {
        flex-direction: column;
        align-items: flex-start;
      }
      .banner-image {
        margin-bottom: ${toRem(16)};
      }
    }
    &.travelInsurance {
      padding: ${toRem(10)} ${toRem(24)} ${toRem(23)};
      .flex-1 {
        flex-direction: column;
        align-items: flex-start;
      }
      .banner-image {
        margin-bottom: ${toRem(5)};
      }
    }
    &.travelInsuranceBanner {
      flex-direction: column;
      text-align: center;
      grid-template-columns: 1fr;
      justify-items: center;
      grid-template-rows: 0.5fr 1fr 0.2fr;
      padding: 0;
      .banner-image {
        height: ${toRem(44)};
        width: ${toRem(173)};
        margin: 0;
      }

      .flex-1 {
        flex: 1;
        flex-direction: column;

        .heading {
          font-size: ${toRem(18)};
        }
        .description {
          font-size: ${toRem(14)};
        }
        .title-wrapper {
          font-weight: ${theme.fonts.fontWeightRegular};
          text-align: center;
          padding: ${toRem(24)} ${toRem(16)} ${toRem(32)};
        }
      }
    }
    &.redemptionAlert {
      .flex-1 {
        .icon-free-nights {
          height: ${toRem(32)};
          width: ${toRem(32)};
          border-radius: ${toRem(16)};
        }
        .icon-extra-free-nights:before {
          height: ${toRem(12.83)};
          width: ${toRem(12.83)};
          padding: ${toRem(3)} ${toRem(8)};
        }
        .heading {
          font-size: ${theme.fonts['fontS']};
        }
        .description {
          font-size: ${theme.fonts['fontXs']};
        }
      }
    }
  }
`;

export const StyledSection = styled.section`
  display: flex;
  align-items: center;
`;

export const StyledImageContainer = styled.div`
  &.travelInsuranceBanner {
    @media ${theme.mediaQuery.allMobiles} {
      display: flex;
      justify-content: center;
      margin-right: ${toRem(0)} !important;
      width: 100%;
    }
  }

  width: ${toRem(82.7)};
  &:dir(rtl) {
    margin-left: ${toRem(20)};
  }
  &:dir(ltr) {
    margin-right: ${toRem(20)};
  }

  &.checkinBanner {
    margin-right: ${toRem(16)};
    @media ${theme.mediaQuery.allMobiles} {
      width: ${toRem(0)};
      .banner-image {
        display: none;
      }
    }
  }

  @media ${theme.mediaQuery.allMobiles} {
    &.travelInsuranceBanner {
      display: flex;
      align-items: center;
    }
  }

  @media ${theme.mediaQuery.allTablets} {
    width: ${toRem(100)};
  }
`;
