/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/ban-ts-comment */
/* eslint-disable  @typescript-eslint/no-var-requires */
// @ts-ignore
import { useEffect, useState } from 'react';
import { useDCAPageModelStore, useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { transformTargetModel } from '../lib/dynamicContentAssemblyUtils';
import { targetPayloadRequest } from '../lib/dynamicContentAssemblyUtils/targetSDKPayload';
import { dcaDynamicContent } from '@marriott/mi-merch-graphql';
import { useNextMiApolloClient } from '@marriott/mi-apollo-client-utils';
import createMappingModelJSON from '../lib/dynamicContentAssemblyUtils/responseMapping';
import operationSignatures from '../lib/dynamicContentAssemblyUtils/_constants/operationSignatures.json';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { useDatalayer } from './use-datalayer';
import isEmpty from 'lodash/isEmpty';

const transformJSON = require('json-map-transform');

declare global {
  interface Window {
    sessionData: any;
    mvpOffers?: {
      specialMessage?: string;
      rpcCode?: string;
      offerCode?: string;
      source?: string;
    };
    preQualShowOfferEvent: () => void;
    _satellite: Record<string, any>;
  }
}

export const useDCADynamicModel = (mboxParam?: string, cellCodes = '') => {
  if (!mboxParam) {
    //If mbox parameter is not present isTargetContentLoading is false and component behavior is not effected by target.
    return { targetData: {} };
  }
  const [compKey, setCompkey] = useState<string | undefined>('');
  const [isPrequal, setIsPrequal] = useState<boolean>(false);
  const { pageModel, updateComponent } = useDCAPageModelStore((state: unknown) => state) as any;
  const { datalayer } = useDatalayer();

  function findKeysWithMatchingValues(obj: any, target: string) {
    let matchingKey = '';
    function search(obj: any, currentKey: string | null = null) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          // If it's a nested object, search recursively
          search(obj[key], key);
        } else if (obj[key] === target) {
          // If the value matches the target, add the key to the list
          if (currentKey) matchingKey = currentKey;
        }
      }
    }
    search(obj);
    return matchingKey;
  }

  function findKeysWithMatchingValuesAndObject(obj: any, target: string) {
    let paths = '';
    function search(obj: any, path: string[] = []) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          search(obj[key], path.concat(key));
        } else if (obj[key] === target) {
          paths = path.join('.');
        }
      }
    }
    search(obj);
    return paths;
  }

  function getDataFromPath(obj: any, path: string): any {
    if (typeof path !== 'string' || !path) return undefined;
    return path.split('.').reduce((currentObj, key) => {
      if (currentObj && typeof currentObj === 'object' && key in currentObj) {
        return currentObj[key];
      }
      return undefined;
    }, obj);
  }

  useEffect(() => {
    if (!isEmpty(pageModel) && !compKey) {
      const compKey = findKeysWithMatchingValues(pageModel, mboxParam);
      setCompkey(compKey);
    }
  }, [pageModel]);

  useEffect(() => {
    const fetchAndUpdateTargetData = async () => {
      const options = targetPayloadRequest(
        {
          locale:
            window?.sessionData?.locale?.replace('-', '_') ||
            window?._satellite?.['getVar']('brwsrLang') ||
            datalayer?.['brwsrLang'] ||
            'en_US',
          mboxName: mboxParam,
          propertyToken:
            process.env['TARGET_PROPERTY_TOKEN'] ||
            useClientEnvVarsStore.getState().envVarsObject['TARGET_PROPERTY_TOKEN'],
          isACDL: useClientEnvVarsStore.getState().envVarsObject?.['NEXT_PUBLIC_ENABLE_WEBSDK'],
          cellCodes: cellCodes,
        },
        datalayer
      );
      if (mboxParam && compKey && !AuthoringUtils.isInEditor() && !AuthoringUtils.isPreviewMode()) {
        try {
          const MiApolloClient = useNextMiApolloClient(
            operationSignatures,
            useClientEnvVarsStore.getState().envVarsObject['DEPLOYED_ENV_TYPE']
          );
          const { data, errors } = await MiApolloClient.query({
            query: dcaDynamicContent,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            variables: {
              input: options,
            },
            context: {
              headers: {},
            },
          });
          const resp = data.dynamicContentByChannelAndMboxes.mboxes[0];
          let transformerData;
          if ((errors && errors.length) || !resp) {
            throw new Error('DCA call error or mbox parameter is empty');
          }
          if (resp) {
            const mappingModelJSON = createMappingModelJSON();
            // Mapping should happen at AEM level, need to check with AEM team on why there is a gap
            const mappedResponse = transformJSON(resp, mappingModelJSON);
            const requiredPath = findKeysWithMatchingValuesAndObject(pageModel, mboxParam);
            const compModel = getDataFromPath(pageModel, requiredPath);
            transformerData = transformTargetModel(compModel, mappedResponse);
            if (String(resp?.metadata?.prequal) === 'true') {
              setIsPrequal(true);
            }
          }
          if (transformerData) {
            // Create a new object with isTargetContentLoaded property
            const updatedTransformerData = { ...transformerData, isTargetContentLoaded: true };
            updateComponent(compKey, updatedTransformerData, requiredPath);
          }
        } catch (error) {
          console.log(error);
          // Create a new object with isTargetContentLoaded property
          const requiredPath = findKeysWithMatchingValuesAndObject(pageModel, mboxParam);
          const compModel = getDataFromPath(pageModel, requiredPath);
          const transformerData = {
            ...compModel,
            isTargetContentLoaded: true,
            isTargetContentError: true,
          };
          if (transformerData) {
            // Create a new object with isTargetContentLoaded property
            const updatedTransformerData = { ...transformerData, isTargetContentLoaded: true };
            updateComponent(compKey, updatedTransformerData, requiredPath);
          }
        }
      }
    };
    if (!isEmpty(datalayer)) {
      fetchAndUpdateTargetData();
    }
  }, [mboxParam, updateComponent, compKey, datalayer]);

  useEffect(() => {
    // fail safe condition if the preQualShowOfferEvent is not available on window
    if (typeof window?.preQualShowOfferEvent === 'function' && isPrequal) {
      window?.preQualShowOfferEvent();
    }
  }, [isPrequal]);

  const requiredPath = findKeysWithMatchingValuesAndObject(pageModel, mboxParam);
  const compModel = getDataFromPath(pageModel, requiredPath);
  return {
    targetData: compModel || {},
  };
};
