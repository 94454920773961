// Imports for external libraries go here.
import React, { FC } from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { CustomHeadProps } from './CustomHead.types';
import { PreloadFontsLink } from '@marriott/mi-headless-utils';
import { isCNLocale } from '@marriott/shared/mi-helper-utils';

// below code to block header few seconds to getUserdetails response
export const getHeaderScript = function () {
  return `(function (g, b, d, f) {
    (function (a, c, d) {
        if (a) {
        const e = b.createElement('style');
        e.id = c;
        e.innerHTML = d;
        a.appendChild(e);
        }
    })(b.getElementsByTagName('head')[0], 'at-body-style', d);
    setTimeout(function () {
        const a = b.getElementsByTagName('head')[0];
        if (a) {
        const c = b.getElementById('at-body-style');
        c && a.removeChild(c);
        }
    }, f);
    })(window, document, '.header,.m-header,.ham-header {opacity: 0 !important}', 3E3);`;
};

export const getSessionDataScript = function (sessionData: CustomHeadProps['sessionData']) {
  return `var sessionData = ${sessionData ? JSON.stringify(sessionData) : '{}'};`;
};

// Use named rather than default exports.
export const CustomHead: FC<CustomHeadProps> = ({ sessionData, isAcdl, pageProps, pageLocale }) => {
  const { CLIENT_DATA_LAYER_PATH } = process.env;
  const { ENABLE_CLIENT_DATA_LAYER } = process.env;

  const dataLayerPath = pageLocale ? `${CLIENT_DATA_LAYER_PATH}?locale=${pageLocale}` : CLIENT_DATA_LAYER_PATH;

  return (
    <>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      {/* CNWEB-2400 The following CSP directive is to avoid a mixed content blocked error for an http asset called from baidu script, and upgrade the request to use https */}
      <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      <link rel="preconnect" href="https://assets.adobetm.com" />
      <link rel="dns-prefetch" href="https://assets.adobetm.com" />
      <link rel="preconnect" href="https://cache.marriott.com" crossOrigin="anonymous" />
      <link rel="preconnect" href="https://cache.marriott.com" />
      <link rel="dns-prefetch" href="https://cache.marriott.com" />

      {isCNLocale(pageLocale) ? (
        <>
          <link rel="preconnect" href="https://cache.marriott.com.cn" crossOrigin="anonymous" />
          <link rel="preconnect" href="https://cache.marriott.com.cn" />
          <link rel="dns-prefetch" href="https://cache.marriott.com.cn" />
        </>
      ) : null}

      <PreloadFontsLink locale={pageLocale} />
      <link rel="shortcut icon" href="https://cache.marriott.com/Images/Mobile/MC_Logos/MarriottApple57x57.png" />
      {ENABLE_CLIENT_DATA_LAYER === 'true' && (
        <script type="text/javascript" data-testid="testDataLayer" src={dataLayerPath}></script>
      )}
      <script
        id="miSessionData"
        data-testid="miSessionData"
        dangerouslySetInnerHTML={{
          __html: getSessionDataScript(sessionData),
        }}
      ></script>

      {!isAcdl && pageProps?.model?.dataLayerLaunchScript && (
        <script type="text/javascript" src={pageProps?.model?.dataLayerLaunchScript}></script>
      )}

      {isAcdl && pageProps?.model?.dataLayerInitializationScript && (
        <>
          <script type="text/javascript" src={pageProps.model.dataLayerInitializationScript}></script>
          <script type="text/javascript" src={pageProps.model?.dataLayerLaunchScript}></script>
        </>
      )}

      <script
        id="miHeaderVisible"
        dangerouslySetInnerHTML={{
          __html: getHeaderScript(),
        }}
      ></script>
    </>
  );
};
