import { TrackingPropsInterface } from '../../utils/enums/enums';
import { tags, renditions } from '../../utils/enums/enums';

export enum CardVerticalVariations {
  'Standard' = 'standard',
  'Tall1x1' = 'Tall1:1',
  'Tall16x9' = 'Tall16:9',
}

export interface CardVerticalProps {
  cardVerticalVariations:
    | CardVerticalVariations.Tall1x1
    | CardVerticalVariations.Tall16x9
    | CardVerticalVariations.Standard;
  openInNewTab?: boolean;
  rel?: string;
  icon?: string;
  eyebrow?: string | undefined;
  badgeIcon?: string;
  badgeText?: string;
  ctaLink: string;
  ctaType?: string;
  iconText?: string;
  iconFontValue?: string;
  header?: string;
  headerTag?: tags;
  description?: string | undefined;
  ctaLinkText?: string | undefined;
  dynamicMedia?: renditions;
  trackingProperties?: TrackingPropsInterface;
  styleclass?: string;
  componentName?: string;
  componentId?: string;
  custom_click_track_value?: string;
  hideTopicDetail?: boolean;
}
