// Navigation.tsx
// Imports for external libraries go here.
import React, { FC, useEffect, useState, useRef, MutableRefObject } from 'react';
import clsx from 'clsx';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { NAV_MAX_LENGTH, STR_CLOSE_MENU, STR_OPEN_MENU } from './_constants';
import { StyledNavigation } from './Navigation.styles';
import { NavigationProps } from './Navigation.types';
import { PrimaryNavItem } from './PrimaryNavItem';
import { NavigationUtilityLink } from './NavigationUtilityLink/NavigationUtilityLink';
import { AEMModel, Button, Icon } from '@marriott/mi-ui-library';
import { useNavContext } from '../../organisms/NavigationContainer/NavigationContextProvider';

// Use named rather than default exports.
export const Navigation: FC<NavigationProps> = props => {
  const {
    cqPath,
    isAuthorMode,
    primaryNavigationItems,
    utilityNavigation,
    setIsMegaMenuActive,
    megaMenuCloseButtonLabel,
  } = props;
  const mainNavRef = useRef(null) as unknown as MutableRefObject<HTMLDivElement | null>; //Reference to <nav> element ('.main-nav__nav')
  const mainNavMenuButtonRef = useRef(null) as unknown as MutableRefObject<HTMLDivElement | null>; //Reference to .main-nav__menu-btn
  const mainNavOverlayRef = useRef(null) as unknown as MutableRefObject<HTMLDivElement | null>; //Reference to .main-nav__overlay
  const [activeMainNav, setActiveMainNav] = useState<number | null>(null); //Keeps track of primary nav link is active (clicked to display mega-menu)
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const primaryNavRefs = useRef<HTMLAnchorElement[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialTabIndex = useRef(0) as unknown as MutableRefObject<any>;
  const { isInverse, isMainMenuOpen, setIsMainMenuOpen } = useNavContext();

  const handleClickOutsideMegaMenu = (event: MouseEvent) => {
    if (mainNavRef?.current && !mainNavRef?.current?.contains(event.target as Node)) {
      setIsMegaMenuActive(false);
      setActiveMainNav(null);
    }
  };

  function calculateInitialTabIndex() {
    // Assuming other elements on the page are before the navigation
    const otherElementsCount = 1; // Replace with the actual count of other elements - hardcoding now for simplicity until logo is on page
    return otherElementsCount;
  }

  useEffect(() => {
    //when we click outside the megamenu, it should close

    document.addEventListener('click', handleClickOutsideMegaMenu);
    return () => {
      document.removeEventListener('click', handleClickOutsideMegaMenu);
    };
  }, []);

  useEffect(() => {
    initialTabIndex.current = calculateInitialTabIndex();
  }, []);

  const handleMenuToggle = () => {
    setIsMainMenuOpen(prevState => !prevState);
  };

  //changes Nav overflow style. Usecase: change overflow of nav when megamenu is mounted or unmounted.
  const changeNavOverFlowStyle = (overflow: 'hidden' | 'auto' | 'visible' | '') => {
    if (!mainNavRef?.current) {
      return;
    }
    mainNavRef.current.style.overflow = overflow;
  };
  const primaryNavItems = primaryNavigationItems // Only render up to max items.
    ?.filter((_item: AEMModel, index: number) => {
      return index + 1 <= NAV_MAX_LENGTH;
    })
    .map((item: AEMModel, index: number) => {
      return (
        <PrimaryNavItem
          key={index}
          cqPath={`${cqPath}/${item?.itemKey}`}
          isAuthorMode={isAuthorMode}
          primaryNavigationItem={item}
          index={index}
          primaryNavRefs={primaryNavRefs}
          changeNavOverFlowStyle={changeNavOverFlowStyle}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          setIsMegaMenuActive={setIsMegaMenuActive}
          activeMainNav={activeMainNav}
          setActiveMainNav={setActiveMainNav}
          utilityNavigation={utilityNavigation}
          megaMenuCloseButtonLabel={megaMenuCloseButtonLabel}
        />
      );
    });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const menuNav = mainNavRef?.current;
      const menuButton = mainNavMenuButtonRef?.current;
      const overlay = mainNavOverlayRef?.current;

      if ((!menuNav?.contains(target) && !menuButton?.contains(target)) || overlay?.contains(target)) {
        setIsMainMenuOpen(false);
      }
    };

    if (isMainMenuOpen) {
      document.addEventListener('click', handleClickOutside, true);
      document.body.style.overflow = 'hidden'; // Prevent scrolling when the menu is open
    } else {
      document.removeEventListener('click', handleClickOutside);
      document.body.style.overflow = 'auto'; // Restore scrolling when the menu is closed
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.body.style.overflow = 'auto'; // Restore scrolling on unmount as well
    };
  }, [isMainMenuOpen, setIsMainMenuOpen]);

  return (
    <StyledNavigation data-component-name="m-common-static-Navigation" data-testid="common-static-Navigation">
      <div className={clsx('main-nav__content', { 'main-nav__open ': isMainMenuOpen })} data-testid="primarynavigation">
        <Button
          id="menuButton"
          className={'main-nav__btn main-nav__menu-btn d-lg-none main-nav__menu-btn--close'}
          ariaExpanded={isMainMenuOpen}
          callback={handleMenuToggle}
          ariaLabel={isMainMenuOpen ? STR_CLOSE_MENU : STR_OPEN_MENU}
          ref={mainNavMenuButtonRef}
        >
          <Icon
            iconClass={clsx('icon-l', {
              'main-nav__menu-close-icon icon-clear': isMainMenuOpen,
              'main-nav__menu-open-icon icon-menu': !isMainMenuOpen,
              'icon-inverse': isInverse,
            })}
          />
        </Button>
        <nav
          ref={mainNavRef}
          className={clsx('main-nav__nav ', { 'd-flex standard t-background-color': isMainMenuOpen })}
        >
          {isMainMenuOpen && <div className="main-nav__overlay" ref={mainNavOverlayRef}></div>}
          <ul className={'main-nav__nav-items main-nav'} role="menu" aria-labelledby="menubutton">
            {primaryNavItems}
          </ul>
          {utilityNavigation?.[':items'] && (
            <div className="nav-utility-container d-lg-none">
              <NavigationUtilityLink utilityNavigation={utilityNavigation} isRenderedInsideOverlay={true} />
            </div>
          )}
        </nav>
      </div>
    </StyledNavigation>
  );
};
