import styled from 'styled-components';
import { toRem, theme } from '../../styles';

export const StyledDiv = styled.div`
  textarea {
    outline: none;
    border: none;
    border-radius: 0;
    width: 100%;
    height: ${toRem(43)};
    font-size: ${theme.fonts['fontL']};
    line-height: ${theme.fonts['lineHeightXl']};
    caret-color: ${theme.colors.carrot};
    color: ${theme.colors['dark-gray-2']};
    padding: ${toRem(16)} ${toRem(48)} ${toRem(8)} 0;
    border-bottom: ${theme.colors['dark-gray-3']} solid ${toRem(2)};
    &:focus {
      border-bottom: ${theme.colors['dark-gray-2']} solid ${toRem(2)};
    }
    &.is-error {
      border-bottom: var(--t-form-error-color) solid ${toRem(3)};
    }
    &::placeholder {
      color: ${theme.colors['dark-gray-3']};
    }
  }

  fieldset:focus-within {
    border: ${theme.colors['dark-gray-2']} solid ${toRem(2)};
  }

  .outlined {
    border: ${theme.colors['dark-gray-3']} solid ${toRem(1)};
    border-radius: ${toRem(4)};
    position: relative;
    margin-top: ${toRem(15)};

    textarea {
      border-bottom: 0;
      padding: ${toRem(16)} ${toRem(48)} ${toRem(8)} ${toRem(16)};
    }

    &.is-error {
      border: var(--t-form-error-color) solid ${toRem(2)} !important;
    }

    &:focus-within > .outlined-label {
      color: ${theme.colors['base-10']};
    }
  }
  .outlined-label {
    width: auto;
    margin: 0;
    padding: ${toRem(6)};
    position: absolute;
    top: ${toRem(-15)};
    inset-inline-start: ${toRem(12)};
    background-color: ${theme.colors.white};
    font-size: ${theme.fonts['fontS']};
    color: ${theme.colors['dark-gray-3']};
    line-height: ${theme.fonts['lineHeightXs']};
    font-weight: ${theme.fonts['fontWeightMedium']};
  }

  .no-border {
    border: 0 !important;
  }

  label {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: ${toRem(1.3)};
    font-size: ${theme.fonts['fontXs']};
    color: ${theme.colors['dark-gray-3']};
    line-height: ${theme.fonts['lineHeightXs']};
    font-weight: ${theme.fonts['fontWeightMedium']};
  }
`;

export const StyledDescription = styled.p`
  margin: ${toRem(8)} 0 0;
  color: ${theme.colors['base-10']};
  font-size: ${theme.fonts['fontM']};
  line-height: ${theme.fonts['lineHeightL']};
`;

export const StyledError = styled.p`
  margin: ${toRem(5)} 0 0;
  color: ${theme.colors.monza};
  font-size: ${theme.fonts['fontS']};
  line-height: ${theme.fonts['lineHeightM']};

  &.icon-information::before {
    margin-right: ${toRem(9.3)};
  }
`;
