/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Imports for external libraries go here.
import React, { FC, useEffect, useRef, useState } from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import {
  Button,
  Messages,
  getCurrentDateObject,
  getDateFormatted,
  getLocalizedDate,
  getValueFromDate,
} from '@marriott/mi-ui-library';
import { isCNLocale } from '@marriott/shared/mi-helper-utils';
import clsx from 'clsx';
import { SearchField, DatePicker, RoomsAndGuests, SpecialRates } from '../../molecules';
import { AlertMessageBodyProps, SearchFormPrimaryBlockProps } from './SearchForm.types';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { formSubmitSearchRequestParams, removePeriods } from '../../modules/utils';
import {
  groupPartnersError,
  searchFormClickTrackingLoc,
  travelAgentsError,
  travelAgentsErrorClickTrackingValue,
  groupPartnersErrorClickTrackingValue,
  LATIN_NAME_REGEX,
  corpCodeLocalVal,
  corpCodeVal,
  locationBlankInvalidError,
  roomCountBetweenThreeAndTenError,
  corporateCodeFormatLengthInvalidError,
  corporateCodeFormatInvalidError,
  roomCountFourToNine,
  hardCodedAllLocale,
  localizedFormattingRequiredLocales,
} from '../../constants/lib/constants';
import { useStaticDataContext } from '../../modules/context';
import {
  SearchFormActiveState,
  checkInFutureDateError,
  checkOutFutureDateError,
  checkInPastDateError,
  checkOutPastDateError,
} from '../../constants';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { convertToLowerCase, displaySessionBasedAlerts, returnApplicableAlert } from '../../utils/src/utils';
import { usePageContext } from '../../context';
import { phoenixShopAdvSearchInventoryDate } from '../../molecules/SearchField/queries';
import { useApolloClient } from '@apollo/client';
import { ERROR_MESSAGE } from '../../modules/store/store.constants';
import moment from 'moment';
import { JP_LOCALE, KR_LOCALE } from '../../modules/constants';
import { useCheckBreakpoint } from '../../hooks/lib/useCheckBreakpoint';

export const SearchFormSubmitButton: FC<{
  findHotelLabel: string | undefined;
  submitAction: string | undefined;
  customClass?: string;
  alertsListArray?: any;
  lastElementRef?: any;
  sessionData?: any;
  isAvailabilityScenario?: boolean;
}> = ({
  findHotelLabel,
  submitAction,
  customClass,
  alertsListArray,
  lastElementRef,
  sessionData,
  isAvailabilityScenario,
}) => {
  const searchformRef = useRef(null);
  const { envVarsObject } = useClientEnvVarsStore();
  const { isTabbedSearchForm, localDateFormat } = useStaticDataContext(); // please do not remove this , breaks the form , its been used
  const isDesktopView = useCheckBreakpoint('viewportL');
  const isTabletView = useCheckBreakpoint('viewportM');
  const { currentLocale } = usePageContext();
  /**
   * return search form submit button for mobile/desktop view
   */
  const destination = useSearchFormStore((state: any) => state?.destination);
  const destinationHiddenFields = useSearchFormStore((state: any) => state?.destinationHiddenFields);
  const roomsAndGuests = useSearchFormStore((state: any) => state?.roomsAndGuests);
  const dates = useSearchFormStore((state: any) => state?.dates);
  const usePoints = useSearchFormStore((state: any) => state?.usePoints);
  const brands = useSearchFormStore((state: any) => state?.brands);
  const specialRates = useSearchFormStore((state: any) => state?.specialRates);
  const destinationFieldDetails = useSearchFormStore((state: any) => state?.destinationFieldDetails);
  const resortsHiddenFields = useSearchFormStore((state: any) => state?.resortsHiddenFields);
  const setSearchFormState = useSearchFormStore((state: any) => state.setSearchFormState);

  const marriottBrands = brands?.marriottBrands && brands?.marriottBrands;
  const today = getCurrentDateObject();
  const from: moment.Moment | null | undefined = today;
  const currentDate = getValueFromDate(from);
  const fromDate = getValueFromDate(dates?.fromDate);

  /**
   * return button
   */
  const handleSubmit = () => {
    if (isTabbedSearchForm) {
      setSearchFormState([ERROR_MESSAGE], {
        [ERROR_MESSAGE]: {
          errorMessages: '',
        },
      });
      if (!destination?.displayText) {
        setSearchFormState([ERROR_MESSAGE], {
          [ERROR_MESSAGE]: {
            errorMessages: returnApplicableAlert(locationBlankInvalidError, alertsListArray),
          },
        });
      } else if (roomsAndGuests?.numRooms?.toString()?.replace('〜', '-') === roomCountFourToNine) {
        setSearchFormState([ERROR_MESSAGE], {
          [ERROR_MESSAGE]: {
            errorMessages: returnApplicableAlert(roomCountBetweenThreeAndTenError, alertsListArray),
          },
        });
      } else if (
        (specialRates?.clusterCode === corpCodeLocalVal || specialRates?.clusterCode === corpCodeVal) &&
        (specialRates?.corporateCode?.length < 2 || specialRates?.corporateCode?.length > 9)
      ) {
        setSearchFormState([ERROR_MESSAGE], {
          [ERROR_MESSAGE]: {
            errorMessages: returnApplicableAlert(corporateCodeFormatLengthInvalidError, alertsListArray),
          },
        });
      } else if (
        (specialRates?.clusterCode === corpCodeLocalVal || specialRates?.clusterCode === corpCodeVal) &&
        LATIN_NAME_REGEX.test(specialRates.corporateCode) !== true
      ) {
        setSearchFormState([ERROR_MESSAGE], {
          [ERROR_MESSAGE]: {
            errorMessages: returnApplicableAlert(corporateCodeFormatInvalidError, alertsListArray),
          },
        });
      } else {
        formSubmitSearchRequestParams(
          destination,
          destinationHiddenFields,
          roomsAndGuests,
          dates,
          usePoints,
          marriottBrands,
          specialRates,
          destinationFieldDetails,
          resortsHiddenFields,
          searchformRef,
          envVarsObject?.['NEXT_PUBLIC_SUBMIT_SEARCH_URL'],
          envVarsObject?.['NEXT_PUBLIC_AVAILABILITY_SEARCH_URL'],
          isTabbedSearchForm,
          currentLocale ?? envVarsObject?.['ACCEPT_LANGUAGE'],
          localDateFormat,
          sessionData,
          isAvailabilityScenario,
          true
        );
      }
    } else {
      if (
        destination.displayText !==
        sessionData?.sessionData?.cacheData?.data?.AriesSearch?.searchCriteria?.address?.destination
      ) {
        if (typeof window !== 'undefined') {
          window.sessionStorage.setItem('OffersfilterData', JSON.stringify({}));
        }
      }
      formSubmitSearchRequestParams(
        destination,
        destinationHiddenFields,
        roomsAndGuests,
        dates,
        usePoints,
        marriottBrands,
        specialRates,
        destinationFieldDetails,
        resortsHiddenFields,
        searchformRef,
        envVarsObject?.['NEXT_PUBLIC_SUBMIT_SEARCH_URL'],
        envVarsObject?.['NEXT_PUBLIC_AVAILABILITY_SEARCH_URL'],
        isTabbedSearchForm,
        currentLocale ?? envVarsObject?.['ACCEPT_LANGUAGE'],
        localDateFormat,
        sessionData,
        isAvailabilityScenario,
        false
      );
    }
  };
  return (
    <div
      className={clsx(
        !isTabbedSearchForm ? 'col-12 col-lg-4' : isTabbedSearchForm ? 'col-12 col-lg-auto' : '',
        'pr-0 pr-md-1 pr-lg-0',
        'pl-0 pl-md-1',
        'pb-4',
        'pb-lg-0',
        'pt-lg-0',
        isTabbedSearchForm ? 'col-md-6' : 'pt-2 pl-lg-3',
        isTabbedSearchForm ? 'secondary-level-button-wrapper' : '',
        customClass
      )}
    >
      <Button
        {...{ custom_click_track_value: `${searchFormClickTrackingLoc} | Find Hotels |internal` }}
        ref={lastElementRef}
        className={clsx(
          'm-button-primary-icon',
          'm-button-l',
          'w-100',
          'update-search-btn',
          isTabbedSearchForm ? 'p-lg-0' : '',
          isTabbedSearchForm && isDesktopView ? 'search-btn' : '',
          'custom_click_track',
          'standard'
        )}
        callback={(): void => {
          //TODO:Alternate method to be added based on the flag
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          submitAction !== 'default'
            ? (): void => {
                return;
              }
            : handleSubmit();
        }}
      >
        <span className="icon-search" />
        {findHotelLabel}
      </Button>
      {submitAction === 'default' && (
        <form ref={searchformRef} id={Date.now().toString() + '-submit-search-form-action'} method="get"></form>
      )}
    </div>
  );
};
export const SearchPrimaryBlock: FC<SearchFormPrimaryBlockProps> = ({
  isDesktopView,
  isDatesSelected,
  setCurrentFormState,
  setMobileFormModalState,
  setIsDestinationParsed,
  setIsDatesSelected,
  isDestinationParsed,
  mobileModalHeading,
  mobilePlaceHolderText,
  alertsListArray,
  isMobileForm,
  isSearchFormSticky,
  baselineComponentTitle,
  isAvailabilityScenario,
  blacklist,
}) => {
  /**
   * search primary block or search top block
   * consist of destination, datepicker and button if required
   * we can render any content on top block as per the requirment
   */
  const destination = useSearchFormStore((state: any) => state?.destination);
  if (destination?.displayText) {
    setIsDestinationParsed?.(destination?.displayText);
  } else {
    setIsDestinationParsed?.('');
  }
  const {
    isTabbedSearchForm,
    hideDestination,
    hideBrandsField,
    errorFullDateFormat,
    errorTrimmedDateFormat,
    dateFormatLanguageLabel,
    disableSpecialRates,
  } = useStaticDataContext();
  const errorMessages = useSearchFormStore((state: any) => state?.errorMessage);
  const errorMessageKey = errorMessages?.errorMessages?.submitSearchFormErrorMessages?.errorMessageKeys[0];
  const client = useApolloClient();
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { ACCEPT_LANGUAGE: currentLocale, REQUEST_ID: requestID } = clientEnvVars;
  const [alertMessage, setAlertMessage] = useState<AlertMessageBodyProps>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let futureDateErrorObj: AlertMessageBodyProps = {};

  function formatAndSetAlertMessage(errorMsgObj: AlertMessageBodyProps, supplementString?: string) {
    let clonedErrorObject = { ...errorMsgObj };
    if (errorMsgObj?.stringNeedsToBeSplit && supplementString) {
      let formattedErrorMessage = '';
      const errorMessageParts = errorMsgObj?.alertMessage?.split('{X}');
      formattedErrorMessage = errorMessageParts?.[0] + supplementString + errorMessageParts?.[1];
      clonedErrorObject = { ...clonedErrorObject, alertMessage: formattedErrorMessage };
    } else {
      const updatedErrorMessage = (errorMsgObj.alertMessage ?? '') + (supplementString ?? '');
      clonedErrorObject = { ...clonedErrorObject, alertMessage: updatedErrorMessage };
    }
    setAlertMessage(clonedErrorObject);
  }

  // Add dates to the future date errors
  function updateFutureDateErrorMessage(inventoryData: any, errorMsgObj: AlertMessageBodyProps) {
    if (inventoryData && Object.keys(inventoryData).length > 0) {
      const alertMsgCopy = errorMsgObj;
      const date = inventoryData?.advancedReservationDateLimit?.singleDateLimit?.value ?? '';
      const formattedTextDate =
        currentLocale === hardCodedAllLocale.de
          ? removePeriods(getDateFormatted(moment(date), errorFullDateFormat), false)
          : getDateFormatted(moment(date), errorFullDateFormat);
      alertMsgCopy.alertMessage =
        alertMsgCopy?.alertMessage +
        formattedTextDate +
        `${currentLocale === hardCodedAllLocale.en || currentLocale === hardCodedAllLocale.gb ? '.' : ''}`;
      formatAndSetAlertMessage(alertMsgCopy);
    }
  }

  // Get inventory Dates
  async function getInventoryDates(errorMsgObj: AlertMessageBodyProps) {
    const { data } = await client.query({
      query: phoenixShopAdvSearchInventoryDate,
      context: {
        headers: {
          // Any header, including x-request-id, can be passed ui-libraryin args with query.
          // If you don't pass it in the authLink will generate a random ID.
          'x-request-id': requestID,
          'accept-language': currentLocale,
        },
      },
    });
    if (data) {
      updateFutureDateErrorMessage(data, errorMsgObj);
    }
  }

  /**
   * Display the error Message if present in the session
   */
  useEffect(() => {
    if (errorMessageKey && isTabbedSearchForm) {
      const errorMsgObj: AlertMessageBodyProps = displaySessionBasedAlerts(alertsListArray, errorMessageKey);
      const alertCodeFromAEM = errorMsgObj?.alertCode?.trim();
      if (alertCodeFromAEM === checkInFutureDateError || alertCodeFromAEM === checkOutFutureDateError) {
        futureDateErrorObj = errorMsgObj;
        getInventoryDates(errorMsgObj);
      } else if (alertCodeFromAEM === checkInPastDateError) {
        const currLocale = dateFormatLanguageLabel ?? 'en-gb';
        require(`moment/locale/${currLocale}`);
        moment.locale(currLocale);
        const todaysDate = moment(new Date());
        const formattedDate = localizedFormattingRequiredLocales.includes(currentLocale?.replace('_', '-'))
          ? getLocalizedDate(todaysDate, errorTrimmedDateFormat, currentLocale)
          : convertToLowerCase(getDateFormatted(todaysDate, errorTrimmedDateFormat), currentLocale);
        formatAndSetAlertMessage(errorMsgObj, formattedDate);
      } else if (alertCodeFromAEM === checkOutPastDateError) {
        const currLocale = dateFormatLanguageLabel ?? 'en-gb';
        require(`moment/locale/${currLocale}`);
        moment.locale(currLocale);
        const tmrwsDate = moment(new Date()).add(1, 'days');
        const formattedDate = localizedFormattingRequiredLocales.includes(currentLocale?.replace('_', '-'))
          ? getLocalizedDate(tmrwsDate, errorTrimmedDateFormat, currentLocale)
          : convertToLowerCase(getDateFormatted(tmrwsDate, errorTrimmedDateFormat), currentLocale);
        formatAndSetAlertMessage(errorMsgObj, formattedDate);
      } else if (alertCodeFromAEM === travelAgentsError) {
        errorMsgObj['clickTrackingText'] = travelAgentsErrorClickTrackingValue;
        formatAndSetAlertMessage(errorMsgObj);
      } else if (alertCodeFromAEM === groupPartnersError) {
        errorMsgObj['clickTrackingText'] = groupPartnersErrorClickTrackingValue;
        formatAndSetAlertMessage(errorMsgObj);
      } else {
        formatAndSetAlertMessage(errorMsgObj);
      }
    } else {
      /**
       * Remove the Alert Message when user came back from Serp
       * If there is an Alert present in previous search
       */

      if (!errorMessageKey && isTabbedSearchForm) {
        formatAndSetAlertMessage({});
      }
    }
  }, [errorMessageKey]);

  useEffect(() => {
    if (isTabbedSearchForm && errorMessages?.errorMessages?.alertMessage) {
      formatAndSetAlertMessage(errorMessages.errorMessages);
    }
  }, [errorMessages]);

  return (
    <>
      {isTabbedSearchForm && baselineComponentTitle && (
        <h1 className={clsx('t-subtitle-xl', 'tabs-sub-heading', 'mb-4')}>{baselineComponentTitle}</h1>
      )}
      {isTabbedSearchForm && alertMessage && alertMessage?.alertMessage && (
        <Messages
          className={clsx('pb-4 hotels-form-error-messages')}
          messageType={alertMessage?.alertType}
          messageHeading={alertMessage?.alertHeading}
          messageText={alertMessage?.alertMessage}
          linkText={alertMessage?.linkText ?? ''}
          AlertLinkTextLabel={alertMessage?.alertLinkTextLabel ?? ''}
          messageLink={alertMessage?.messageLink ?? ''}
          MessageRedirection={alertMessage?.messageRedirection ?? ''}
          clickTrackingText={alertMessage?.clickTrackingText}
        />
      )}
      <div
        className={clsx(
          'row',
          'd-lg-flex',
          'd-block',
          isTabbedSearchForm ? 'p-0 primary-wrapper' : 'primary-wrapper my-1 py-lg-3 px-lg-5 ',
          isCNLocale(currentLocale) ? 'cn-primary-wrapper' : '',
          isTabbedSearchForm ? 'd-md-flex' : '',
          destination?.displayText !== '' && !isTabbedSearchForm ? 'primary-container' : '',
          disableSpecialRates
            ? 'col-lg-8 col-md-12'
            : hideDestination === true && hideBrandsField === true && isDesktopView
            ? 'col-lg-6'
            : ''
        )}
      >
        {hideDestination !== true && (
          <div
            {...{ custom_click_track_value: `${searchFormClickTrackingLoc} | Destination |internal` }}
            className={clsx(
              'standard',
              'col-lg-4',
              'col-12',
              'mb-lg-0',
              isTabbedSearchForm !== true ? 'dotted-divider mb-5' : 'mb-3 col-md-6',
              'custom_click_track'
            )}
          >
            <SearchField
              onChange={(inputValue: string) => {
                !isDatesSelected
                  ? setCurrentFormState?.(SearchFormActiveState.CALENDAR_STATE)
                  : setCurrentFormState?.(SearchFormActiveState.DEFAULT_STATE);
                setMobileFormModalState?.(true);
                setIsDestinationParsed?.(inputValue);
              }}
              onCancel={() => {
                setCurrentFormState?.(SearchFormActiveState.CLOSED_STATE);
              }}
              isModalOpen={false}
              isDesktopView={isDesktopView}
              mobileModalHeading={mobileModalHeading}
              mobilePlaceHolderText={mobilePlaceHolderText}
              isMobileForm={isMobileForm}
              blacklist={blacklist}
            />
          </div>
        )}
        <div
          className={clsx(
            'standard',
            'col-lg-4',
            'col-12',
            isTabbedSearchForm !== true ? 'mb-5' : 'mb-3 col-md-6',
            'mb-lg-0',
            disableSpecialRates
              ? 'col-lg-6 col-md-12'
              : hideDestination === true && hideBrandsField === true && isDesktopView
              ? 'col-lg-7'
              : ''
          )}
        >
          <DatePicker
            onDatePickerChange={() => {
              setMobileFormModalState?.(false);
              setIsDatesSelected?.(true);
              !isDestinationParsed
                ? setCurrentFormState?.(SearchFormActiveState.DESTINATION_STATE)
                : setCurrentFormState?.(SearchFormActiveState.DEFAULT_STATE);
            }}
            onDatePickerCancel={() => {
              setCurrentFormState?.(SearchFormActiveState.CLOSED_STATE);
            }}
            setDefaultDate={true}
            isMobileForm={isMobileForm}
            isSearchFormSticky={isSearchFormSticky}
          />
        </div>
        {isTabbedSearchForm || (isTabbedSearchForm !== true && hideDestination) ? (
          <div
            className={clsx(
              'standard',
              'col-lg-4',
              'col-12',
              isTabbedSearchForm !== true ? 'mb-5' : 'mb-3 col-md-6',
              'mb-lg-0',
              'custom_click_track',
              disableSpecialRates
                ? 'col-lg-6 col-md-12'
                : hideDestination === true && hideBrandsField === true && isDesktopView
                ? 'col-lg-5'
                : ''
            )}
          >
            {<RoomsAndGuests isDesktopView={isDesktopView} isAvailabilityScenario={isAvailabilityScenario} />}
          </div>
        ) : (
          ''
        )}
        {/* for tablet view only */}
        {isTabbedSearchForm && !disableSpecialRates ? (
          <div
            {...{ custom_click_track_value: `${searchFormClickTrackingLoc} | SpecialRates |internal` }}
            className={clsx(
              'd-none d-md-block d-lg-none',
              'standard',
              'col-lg-4',
              'col-12',
              isTabbedSearchForm !== true ? 'mb-5' : 'mb-3 col-md-6',
              'mb-lg-0',
              'standard',
              'custom_click_track'
            )}
          >
            {
              <SpecialRates
                addLabelSpacingForArcTool={true}
                isDesktopView={isDesktopView}
                isAvailabilityScenario={isAvailabilityScenario}
                errorMessage={returnApplicableAlert(corporateCodeFormatLengthInvalidError, alertsListArray)}
              />
            }
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
