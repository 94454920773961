/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useRef, useState, Suspense, useContext } from 'react';
import { DatePickerExactProps, FocusedInputType } from './Datepicker.types'; //TODO: will remove once integration is done
import {
  CALENDAR_MAX_ALLOWED_DAYS,
  VARIATION_DESKTOP,
  VARIATION_VERTICAL,
  START_DATE,
  END_DATE,
  DAY_NUMBER_FOR_WEEKEND,
} from '../../modules/constants';
import { Button, DateObjectType, getCurrentDateObject, Calendar } from '@marriott/mi-ui-library';
import { useStaticDataContext } from '../../modules/context';
import clsx from 'clsx';
import moment from 'moment';
import { StyledDatePickerExactDiv } from './Datepicker.styles';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { tabSelect } from '../../constants/lib/constants';
import { PageContext } from '../../context';

/**
 *
 * @param param0
 * date picker with calendar
 * @returns
 */

const DatePickerExact: FC<DatePickerExactProps> = ({
  focusedInput = START_DATE,
  isMobile: isMobileView = false, // to display the mobile view
  weekdays,
  weekdaysLong,
  weekdaysShort,
  monthsShort,
  months,
  onDateSelection,
  dates,
  resetFlag,
  setResetFlag,
  isDatePickerModalOpen,
  clickTrackingLoc,
  singleDateLimit,
  groupDateLimit,
}) => {
  const pageContext = useContext(PageContext);
  const calendarRef = useRef<any>(null);
  const today = getCurrentDateObject(); //today date
  const setIsWeekendButtonSelected = useSearchFormStore((state: any) => state.setIsWeekendButtonSelected);
  const isWeekendButtonSelected = useSearchFormStore((state: any) => state.isWeekendButtonSelected);
  const tabSelected = useSearchFormStore((state: any) => state.selectedTab);
  const singleDateThresold = new Date(singleDateLimit ? moment(singleDateLimit).format() : '').setDate(
    new Date(singleDateLimit ? moment(singleDateLimit).format() : '').getDate() - 1
  );
  const groupDateThresold = new Date(groupDateLimit ? moment(groupDateLimit).format() : '').setDate(
    new Date(groupDateLimit ? moment(groupDateLimit).format() : '').getDate() - 1
  );

  const selectedTab = tabSelected?.selectedTab;
  let fromDate: moment.Moment | null = null;
  let toDate: moment.Moment | null = null;
  /**
   * store from date and to date based on the searchform we are in
   */
  if (selectedTab === tabSelect?.meetingsTab) {
    fromDate = dates.eventsFromDate ? dates.eventsFromDate : null;
    toDate = dates.eventsToDate || null;
  } else {
    fromDate = dates.fromDate ? dates.fromDate : null;
    toDate = dates.toDate || null;
  }
  const {
    firstDayOfWeek,
    nights,
    nightLabel,
    thisWeekendLabel,
    enableWeekendSelectorOnMobile = true,
    yearSearchLabel,
  } = useStaticDataContext();

  let { currentLocale = pageContext?.currentLocale } = useStaticDataContext();
  currentLocale = currentLocale || document?.documentElement?.lang?.replace('_', '-') || 'en-US';

  /** States */
  const [focusedState, setFocusedState] = useState<FocusedInputType>(focusedInput); // current focus on input
  const [isWeekendDateSelected, setIsWeekendDateSelected] = useState<boolean>(false);

  const setDateHandler = (startDate: DateObjectType | null, endDate: DateObjectType | null | undefined): void => {
    const from = startDate ?? null;
    let end = endDate ?? null;

    // if from and end date are same then remove end date and keep focus on end date
    if (from?.isSame(end)) {
      setFocusedState(END_DATE);
      end = null;
    }
    onDateSelection(from, end, false);
    setIsWeekendButtonSelected(false);
  };

  useEffect(() => {
    if (!resetFlag) {
      calendarRef?.current?.setFromEndDate(fromDate?.toDate(), toDate ? toDate.toDate() : null);
      calendarRef?.current?.setDefaultMonth(fromDate?.toDate());
    } else {
      calendarRef?.current?.resetCalendarDates();
    }
    setIsWeekendDateSelected(today.day() === DAY_NUMBER_FOR_WEEKEND || today.day() === 0);
  }, [isDatePickerModalOpen]);

  useEffect(() => {
    if (resetFlag) {
      setFocusedState(START_DATE);
      setIsWeekendDateSelected(false);
      setIsWeekendButtonSelected(false);
      calendarRef?.current?.setFromEndDate(fromDate?.toDate(), toDate ? toDate.toDate() : null);
      calendarRef?.current?.setDefaultMonth(fromDate?.toDate());
      /**
       * Reset the flag
       */
      setResetFlag(false);
    }
  }, [resetFlag]);

  const weekendSelectorHandler = () => {
    /**
     * week end selector logic
     */
    const day = DAY_NUMBER_FOR_WEEKEND - 1 - today.day();
    /**
     * have to use two diffrent variable for moment
     */
    setIsWeekendButtonSelected(true);
    const startD = moment().add(day, 'day');
    const endD = moment().add(day + 2, 'day');
    onDateSelection(startD, endD, false);
    calendarRef?.current?.setFromEndDate(startD.toDate(), endD.toDate());
  };
  // handler to set fromDate in the formValues

  return (
    <StyledDatePickerExactDiv data-test-id="exact">
      <Suspense fallback={''}>
        <>
          {isMobileView && enableWeekendSelectorOnMobile && (
            <div>
              <div className="weekend-selector-horizontal-line mt-2 mb-2"></div>
              <Button
                className={clsx(
                  'custom_click_track',
                  'm-button-s',
                  'm-button-primary',
                  'weekend-selector',
                  'mt-3',
                  'mb-2',
                  isWeekendDateSelected ? 'disabled' : '',
                  isWeekendButtonSelected ? 'selected-state' : ''
                )}
                {...{
                  custom_click_track_value: `${clickTrackingLoc}| ${thisWeekendLabel} button |internal`,
                }}
                callback={weekendSelectorHandler}
                isDisabled={isWeekendDateSelected}
              >
                <span className={clsx(isWeekendButtonSelected ? 'icon-check' : 'icon-calendar')} />
                {thisWeekendLabel}
              </Button>
            </div>
          )}
          <Calendar
            ref={calendarRef}
            startDate={fromDate}
            endDate={toDate}
            focusedInput={focusedState}
            setDateHandler={setDateHandler}
            setFocusedInputHandler={(newState: FocusedInputType): void => setFocusedState(newState)}
            nightLabel={nightLabel}
            nightsLabel={nights}
            variation={isMobileView ? VARIATION_VERTICAL : VARIATION_DESKTOP}
            maxAllowedDays={
              (selectedTab === tabSelect?.meetingsTab
                ? isMobileView
                  ? moment(groupDateThresold).diff(moment().subtract(35, 'd'), 'd')
                  : CALENDAR_MAX_ALLOWED_DAYS
                : isMobileView
                ? moment(singleDateThresold).diff(moment().subtract(35, 'd'), 'd')
                : CALENDAR_MAX_ALLOWED_DAYS) ?? CALENDAR_MAX_ALLOWED_DAYS
            }
            weekdays={weekdays}
            months={months}
            weekdaysLong={weekdaysLong}
            monthsShort={monthsShort}
            weekdaysShort={weekdaysShort}
            firstDayOfWeek={firstDayOfWeek}
            showOutsideDays={true}
            defaultMonth={fromDate?.toDate()}
            isThereTwoDateInputBox={true}
            fixedWeeks={false}
            scrollIntoViewBlock="center"
            locale={currentLocale}
            yearLabel={yearSearchLabel}
            customToDate={
              selectedTab === tabSelect?.meetingsTab
                ? groupDateLimit
                  ? new Date(groupDateThresold)
                  : undefined
                : singleDateLimit
                ? new Date(singleDateThresold)
                : undefined
            }
            isPastDateSelectionDisabled={true}
            customDatesFlag={
              selectedTab === tabSelect?.meetingsTab
                ? groupDateLimit
                  ? true
                  : undefined
                : singleDateLimit
                ? true
                : undefined
            }
          />
        </>
      </Suspense>
    </StyledDatePickerExactDiv>
  );
};

export default DatePickerExact;
