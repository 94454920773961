// adding this here because of this error:
//  error  A project tagged with "type:product-components" can only depend on libs tagged with "scope:mi-ui-library", "type:util", "type:data-access"  @nx/enforce-module-boundaries
import { Types } from '@marriott/mi-ui-library';

const { INTERNAL, EXTERNAL } = Types.LinkTargetType;

export const TRACKING_SEARCH_URL = 'marriott.com';

export type ClickTrackingProps = {
  fallbacks: {
    position: string;
    description: string;
  };
  url?: string | null;
  trackingProperties?: Types.TrackingPropsInterface;
};

export const getClickTrackValue = (
  { fallbacks, url, trackingProperties }: ClickTrackingProps,

  /* TODO: remove this. this is temporary to achieve parity with prod values. eventually we should use actual
    position (e.g. GlobalNav, Footer) and description (e.g. Help, Careers, Privacy Policy) */
  labelOverride?: string
) => {
  return [
    labelOverride || trackingProperties?.trackingContentPosition || fallbacks.position,
    labelOverride || trackingProperties?.trackingDescription || fallbacks.description,
    url && !url.includes(TRACKING_SEARCH_URL) ? EXTERNAL : INTERNAL,
  ].join('|');
};
