import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const PostcardHolder = styled.div`
  border: solid thin ${baseVariables.color['neutral20']};
  border-radius: ${toRem(14)};
  height: ${toRem(515)};
  contain: content;

  .row {
    height: 100%;
    border-radius: ${toRem(14)};
  }
  padding: 0;

  @media ${baseVariables.mediaQuery['md']} {
    height: ${toRem(405)};
  }

  @media ${baseVariables.mediaQuery['lg']} {
    height: ${toRem(641)};
  }
`;

export const PostcardContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  align-items: center;

  .t-subtitle-xl {
    color: ${baseVariables.color['neutral20']};
  }
  .description-desktop {
    display: none;
  }
  .description-short {
    display: contents;
  }

  @media ${baseVariables.mediaQuery['md']} {
    justify-content: end;
    padding: 0;

    .description-short {
      display: none;
    }

    .description-desktop {
      display: contents;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: ${toRem(32)};
  justify-content: center;
  width: fill-available;
  flex-direction: column;
  width: max-content;

  button:last-of-type {
    margin-right: 0;
  }
  .postcard-button {
    margin-right: 0;
  }

  @media ${baseVariables.mediaQuery['md']} {
    flex-direction: row;

    .postcard-button {
      margin-right: ${toRem(16)};
    }
  }
`;

export const PostcardTitle = styled.div`
  p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
  }
  padding-bottom: 12px;
`;

export const PostcardSubtitle = styled.div`
  p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: 0;
    margin-right: 24px;
    margin-left: 24px;
    font-size: ${toRem(18)};
    @media ${baseVariables.mediaQuery['md']} {
      font-size: ${toRem(29)};
    }
  }
`;

export const PostcardBackground = styled.div`
  align-items: center;
  align-content: center;
  border-radius: ${toRem(14)};
  justify-content: center;
`;

// Taken from hero banner since they're using the image component
export const StyledImage = styled.div`
  .pc__img {
    display: block;
    filter: brightness(50%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    @supports (object-fit: cover) {
      object-fit: cover;
      width: 100%;
    }
    @media ${baseVariables.mediaQuery['md']} {
      top: 0;
      transform: translate(-50%, 0);
      width: 100%;
    }
  }
`;
