/* eslint-disable @nx/enforce-module-boundaries */
// Imports for external libraries go here.
import { FC, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import clsx from 'clsx';

import { Button, canUseDOM, useCheckBreakpoint } from '@marriott/mi-ui-library';
import { useGlobalStore, useUserDetailsStore } from '@marriott/mi-store-utils';

import { PropertyBarProps, SavedProperty, UpdateSavePropertiesResult } from './PropertyBar.types';
import { FlexAnchor, StyledPropertyBar } from './PropertyBar.styles';
import { propertyBarQuery, propertyBarAuthQuery, updateSaveProperties } from './queries';
import { getSavedProperties } from './utils';
import { GOOGLE_MAP_URL, SAVE_PROPERTY_URL } from './constants';
import { addSubDirectoryPrefix } from '../../utils';
import { getClickTrackValue } from '@marriott/mi-common-static-components';
import { usePropertyBar } from './use-property-bar';
export const PropertyBar: FC<PropertyBarProps> = ({
  hotelLabel,
  phoneLabel,
  mapLabel,
  saveLabel,
  savedLabel,
  starsOutOfText,
  locale,
  returnToPath,
  trackingProperties,
}) => {
  const { setPropertyDetails } = usePropertyBar();
  const { sessionData } = useGlobalStore(state => state);
  const { isSignedInUser } = useUserDetailsStore(state => ({
    isSignedInUser: state.isSignedInUser,
  }));
  const isDesktopView = useCheckBreakpoint('viewportM'); // tablet and nigher

  // find the property id from the global store with fallback to datalayer window object
  const propertyId =
    (canUseDOM &&
      ((sessionData?.['cacheData']?.data?.AriesSearch?.searchCriteria?.propertyId ||
        window.dataLayer?.['prop_marsha_code'] ||
        window.dataLayer?.['mrshaCode']) as string | undefined)) ||
    'NYCES';

  const [isSavedProperty, setIsSavedProperty] = useState<boolean>(false);
  const variables: { propertyId: string; customerId?: string } = { propertyId: propertyId as string };
  if (isSignedInUser) {
    variables.customerId = sessionData?.['cacheData']?.data?.['consumerID'];
  }

  // fetch data hotel data for either signed in or signed out users.
  const { data } = useQuery(isSignedInUser ? propertyBarAuthQuery : propertyBarQuery, {
    fetchPolicy: isSignedInUser ? 'no-cache' : 'cache-first',
    context: {
      headers: {
        'accept-language': locale,
      },
    },
    skip: !propertyId,
    variables,
    onCompleted: data => {
      // check to see if the property is saved by the user
      setIsSavedProperty(
        data?.customer?.savedProperties?.find((property: SavedProperty) => property.id === propertyId) !== undefined
      );
    },
    onError: error => console.error('Error fetching property data', error),
  });

  const [updateCustomerSavedProperties] = useMutation(updateSaveProperties, {
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'accept-language': locale,
      },
    },
    onCompleted: (data: UpdateSavePropertiesResult) => {
      // check to see if the property is saved by the user
      setIsSavedProperty(
        data?.updateCustomerSavedProperties?.savedProperties?.find(
          (property: SavedProperty) => property.id === propertyId
        ) !== undefined
      );
    },
    onError: error => console.error('Error while updating properties', error),
  });

  const handleSaveProperty = async () => {
    const properties = getSavedProperties(propertyId as string, data?.customer?.savedProperties);
    await updateCustomerSavedProperties({
      variables: {
        input: {
          id: data?.customer?.id,
          revisionToken: data?.customer?.revisionToken,
          savedProperties: properties,
        },
      },
    });
  };
  // example: https://www.marriott.com/en-us/hotels/mspak-emery-autograph-collection/reviews/
  const hotelReviewUrl = addSubDirectoryPrefix(`/hotels/${data?.property?.seoNickname}/reviews`);

  // example: https://www.marriott.com/en-us/hotels/mspak-emery-autograph-collection/overview/
  const hotelDetailUrl = addSubDirectoryPrefix(`/hotels/${data?.property?.seoNickname}/overview`);

  const returnToUrl = addSubDirectoryPrefix(returnToPath as string);

  /**
   * Fire event to show hotel detail passing the review url and the
   * hotel detail url. What listeners of this event do is up to them, most likely
   * open a modal or navigate to a new page.
   */
  const handlePropertyDetail = (event: unknown) => {
    event && (event as Event).preventDefault();
    setPropertyDetails({ showPropertyDetails: true, propertyDetails: { hotelReviewUrl, hotelDetailUrl } });
  };

  if (!data?.property) {
    return null;
  }
  return (
    <StyledPropertyBar className={`brand-css container-xl`}>
      <div className="wrapper">
        <a className="t-subtitle-xl pointer-cursor" href={hotelDetailUrl}>
          {data?.property?.basicInformation?.name}
        </a>
        <div className="property-info">
          <Button
            className="transparent-button d-flex align-items-center px-0 icon-city icon-height t-font-weight-r t-foreground-color transparent-button"
            custom_click_track_value={getClickTrackValue({
              trackingProperties,
              fallbacks: {
                description: 'Hotel Details',
                position: 'PropertyBar',
              },
            })}
            callback={handlePropertyDetail}
          >
            <span className="ml-1 t-font-xs">{isDesktopView && hotelLabel}</span>
          </Button>
          <FlexAnchor
            href={`${GOOGLE_MAP_URL}${data?.property?.basicInformation?.latitude},${data?.property?.basicInformation?.longitude}`}
            target={'_blank'}
            rel="noopener noreferrer"
            className="t-font-weight-r icon-location icon-height t-foreground-color custom_click_track"
            data-custom_click_track_value={getClickTrackValue({
              trackingProperties,
              fallbacks: {
                description: 'Address Link',
                position: 'PropertyBar',
              },
            })}
          >
            <span className="ml-2 t-font-xs">{isDesktopView && mapLabel}</span>
          </FlexAnchor>
          <FlexAnchor
            href={`tel:${data?.property?.contactInformation?.contactNumbers[0]?.phoneNumber?.original}`}
            aria-label={phoneLabel}
            className="icon-phone icon-height t-foreground-color custom_click_track"
            data-custom_click_track_value={getClickTrackValue({
              trackingProperties,
              fallbacks: {
                description: 'Phone Number',
                position: 'PropertyBar',
              },
            })}
          >
            <span className="ml-1 t-font-xs">
              {isDesktopView && data?.property?.contactInformation?.contactNumbers[0]?.phoneNumber?.display}
            </span>
          </FlexAnchor>
          <div>
            <FlexAnchor
              href={hotelReviewUrl}
              target={'_blank'}
              rel="noreferrer"
              data-custom_click_track_value={getClickTrackValue({
                trackingProperties,
                fallbacks: {
                  description: 'Ratings',
                  position: 'PropertyBar',
                },
              })}
              aria-label={`${data?.property?.reviews?.stars?.count} ${starsOutOfText} ${data?.property?.reviews?.numberOfReviews?.count}`}
              className="t-font-weight-r icon-star-fill icon-height t-foreground-color custom_click_track"
            >
              <span className="ml-1 t-font-xs">
                {isDesktopView && data?.property?.reviews?.stars?.count} (
                {data?.property?.reviews?.numberOfReviews?.count})
              </span>
            </FlexAnchor>
          </div>

          {isSignedInUser && data?.customer?.id ? (
            <Button
              className={clsx(
                'transparent-button d-flex align-items-center icon-height t-font-weight-r t-foreground-color',
                isSavedProperty ? 'icon-heart-fill' : 'icon-heart-outline'
              )}
              callback={handleSaveProperty}
            >
              <span className="ml-1 t-font-xs">{isDesktopView && (isSavedProperty ? savedLabel : saveLabel)}</span>
            </Button>
          ) : (
            <FlexAnchor
              className={clsx(
                'icon-height t-font-weight-r t-foreground-color',
                isSavedProperty ? 'icon-heart-fill' : 'icon-heart-outline'
              )}
              href={`${SAVE_PROPERTY_URL}?save=${Boolean(
                isSavedProperty
              ).toString()}&marshaCode=${propertyId}&returnTo=${returnToUrl}`}
            >
              <span className="ml-1 t-font-xs">{isDesktopView && (isSavedProperty ? savedLabel : saveLabel)}</span>
            </FlexAnchor>
          )}
        </div>
      </div>
    </StyledPropertyBar>
  );
};
