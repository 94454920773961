import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledFormContainer = styled.div.attrs(props => props)`
  ${rtl`
    &.serp-search-form{
      min-height: ${toRem(206)};  
      background: ${theme.color.background};
      @media ${theme.mediaQuery.mobileOnly} {
          min-height: ${toRem(134)};  
      }
    }
  `}
`;
