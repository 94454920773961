/* eslint-disable @typescript-eslint/no-explicit-any */
import config from '../config';
import { SuggestionDataTypes } from '../component/index.types';
import { CookieUtils, eventUtil } from '@marriott/mi-ui-library-shop';
import {
  COOKIE_SESSION_ID,
  DATE_FORMAT_VALUE,
  DATE_FORMAT_YEAR_MONTH_DAY,
  REWARDS_REDEMPTION_CLUSTER_CODES,
} from '../constants/ApplicationConstants';
import {
  SPECIAL_RATES,
  DATES,
  DESTINATION,
  ROOMS_AND_GUESTS,
  USE_POINTS,
  DESTINATION_HIDDEN_FIELDS,
  LATITUDE_AND_LONGITUDE,
} from '../constants/StoreConstants';
import { EEO_MEMBER_CORP_CODE } from '../../../../constants/lib/constants';
import { getCurrentDateObject, getDateFormatted, getDateObject, getNextDateObject } from './DateUtils';
import { StoreValuesType, RoomsAndGuestsFieldType } from '../store/store.types';
import Router from 'next/router';
import axios from 'axios';
import { MILESTOMETERS } from '../../SearchResults/lib/application.constants';
import { addSubDirectoryPrefix, getLanguage } from '../../../../utils/src/helper';
import moment from 'moment';
import {
  DATE_DOTTED_FORMAT,
  DATE_MONTH_YEAR_FORMAT,
  DE_LOCALE,
  ES_LOCALE,
  FR_LOCALE,
  GB_LOCALE_PATTERN_HYPHEN,
  IT_LOCALE,
  JP_LOCALE,
  KR_LOCALE,
  PT_BR_LOCALE,
  RU_LOCALE,
  TW_LOCALE,
  YEAR_MON_DATE_DOTTED_FORMAT,
  YEAR_MON_DATE_FORMAT,
  localeToRemoveFromURL,
} from '../../../../modules/constants';
import { hardCodedAllLocale } from '../component/index.constants';
import { getDTTHeaders } from '../../../../utils/src/utils';
import { LOCALE_ZH_CN } from '@marriott/mi-ui-library';
export async function getSearchFormPostData(UUID: string, PID: string): Promise<Record<string, string | number>> {
  const searchParams = new URLSearchParams({
    placeId: PID,
    uuid: UUID,
  });
  const searchURL = `${config.playServicePath}${config.autoSuggestionLocationDetailsAPI}?${searchParams.toString()}`;
  const response = await fetch(searchURL);
  const jsonResponse = await response.json();
  let locationDetails: SuggestionDataTypes;
  let locationHiddenFields: Record<string, string | number> = {};
  if (jsonResponse && jsonResponse.total > 0) {
    locationDetails = jsonResponse.suggestions[0];
    locationHiddenFields = flattenSuggestionData(locationDetails);
  }
  return locationHiddenFields;
}

export function flattenSuggestionData(data: SuggestionDataTypes): Record<string, string | number> {
  const details = data.details;
  const location = details?.location;
  const suggestionHiddenFields = {
    description: data.description,
    placeId: data.placeId,
    radius: details.distance,
    address: location.address,
    latitude: location.latitude,
    longitude: location.longitude,
    city: location.city,
    state: location.state,
    country: location.country,
    countryName: location.countryName,
    website: details.url,
    types: details.types?.join(),
  };
  return suggestionHiddenFields;
}

// Following script should be included in the page first, to make Google Maps API work.
// <script src="//maps.googleapis.com/maps/api/js?v=3.exp&amp;client=gme-marriottinternational&amp;libraries=places,geometry&amp;signed_in=false&amp;region=" async="async" defer="defer"></script>

export function getCurrentLocation(callback: CallableFunction): void {
  let currentLocation: Record<string, string>;

  // fetching the latitude and longitude
  navigator.geolocation.getCurrentPosition(
    (location: GeolocationPosition) => {
      const lat = location.coords.latitude;
      const lng = location.coords.longitude;

      if (window.google) {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = { lat, lng };
        // using Goggle Maps API reverse geocoding service to convert latlng to location details
        geocoder.geocode(
          { location: latlng },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (results: any, status: any) => {
            if (status === 'OK') {
              if (results[0]) {
                // only store the data required for hidden fields and input field
                currentLocation = {
                  placeId: results[0].place_id,
                  address: results[0].formatted_address,
                  types: results[0].types.join(),
                };
                // using callback to pass the data
                callback(currentLocation);
              } else {
                window.alert('No location available.');
              }
            }
          }
        );
      }
    },
    (error: GeolocationPositionError) => {
      window.alert('Please enable geolocation in your browser settings.');
      return error;
    }
  );
}

type RoomsType = Record<string, { selected: string; children: { selected: string; ages: { childrenAges: string } } }>;

/**
 * parse rooms and guests data from API response
 * @param rooms
 */
const getRoomsData = (rooms: RoomsType): RoomsAndGuestsFieldType => {
  const {
    guests: {
      selected: adultsCount,
      children: {
        selected: childrenCount,
        ages: { childrenAges },
      },
    },
    roomCount: { selected: roomsCount },
  } = rooms;
  return {
    roomsCount,
    adultsCount: +adultsCount,
    childrenAges: childrenAges ? childrenAges.split(',') : [],
    childrenCount: +childrenCount,
  };
};

type hiddenFieldType = Record<string, Record<string, { value: string }>>;

/**
 * parse hidden fields data from API response
 * @param hiddenFields
 */
const getHiddenFieldsData = (hiddenFields: hiddenFieldType): Record<string, unknown> => {
  const {
    global: {
      destinationAddressCity: { value: city },
      destinationAddressLatitude: { value: latitude },
      destinationAddressLongitude: { value: longitude },
      destinationAddressTypes: { value: types },
      destinationAddressStateProvince: { value: state },
      destinationAddressAddress: { value: address },
      destinationAddressPlaceId: { value: placeId },
      destinationAddressCountry: { value: country },
      destinationAddressWebsite: { value: website },
      searchRadius: { value: radius },
      destinationAddressMainText: { value: primaryDescription },
      destinationAddressSecondaryText: { value: secondaryDescription },
      destinationAddressPostalCode: { value: postalAddress },
    },
  } = hiddenFields;

  return {
    city,
    state,
    longitude,
    latitude,
    country,
    website,
    radius,
    primaryDescription,
    secondaryDescription,
    postalAddress,
    address,
    placeId,
    types,
  };
};

/**
 * parse API response data
 * @param data
 */
export function parseFormData(data: Record<string, unknown>): StoreValuesType {
  if (data?.['location']) {
    const { useRewards, specialRates, rooms, dates, location, hiddenInputs } = data;

    // destination data
    const {
      destination: { value: destinationText },
    } = location as Record<string, { value: string }>;

    // calendar data
    const {
      checkin: { value: checkin },
      checkout: { value: checkout },
      flexibleDate: { selected: isFlexible },
    } = dates as Record<string, { value: string; selected: boolean }>;

    // use points or rewards checkbox
    const { checked: usePoints } = useRewards as { checked: boolean };

    // special rates
    const { inputs, selected: clusterCode } = specialRates as Record<string, unknown>;
    const [{ value: corporateCode }] = inputs as Array<{ value: string }>;

    let fromDate = getDateObject(checkin, DATE_FORMAT_VALUE);
    let toDate = getDateObject(checkout, DATE_FORMAT_VALUE);

    // if from date or to date is invalid set default values
    if (!fromDate.isValid() || !toDate.isValid()) {
      fromDate = getCurrentDateObject();
      toDate = getNextDateObject(fromDate);
    }

    return {
      [DESTINATION]: {
        displayText: destinationText,
      },
      [ROOMS_AND_GUESTS]: getRoomsData(rooms as RoomsType),
      [DATES]: {
        lastDefaultDate: checkin,
        fromDate,
        toDate,
        flexible: isFlexible ? 'true' : undefined,
        checkin,
        checkout,
      },
      [USE_POINTS]: {
        value: usePoints,
      },
      [SPECIAL_RATES]: {
        displayText: '',
        corporateCode,
        clusterCode: clusterCode as string,
      },
      [DESTINATION_HIDDEN_FIELDS]: getHiddenFieldsData(hiddenInputs as hiddenFieldType),
    };
  }
  return {};
}

/**
 * Get data from Aries API for last successful search result of given sessionId
 * @param sessionID
 */
export async function getSearchFormData(sessionID?: string): Promise<StoreValuesType> {
  const sessionId = sessionID ?? CookieUtils.getCookie(COOKIE_SESSION_ID) ?? '';
  const credentialType: 'same-origin' | 'include' = 'same-origin';
  const url = `${config.playServicePath}${config.searchFormDataAPI}`;

  if (!sessionId) {
    return {};
  }
  const { NEXT_PUBLIC_DEFAULT_LANG } = process.env;

  try {
    const body = JSON.stringify({ sessionToken: sessionId });
    const config: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': getLanguage() || NEXT_PUBLIC_DEFAULT_LANG || '',
      },
      body,
      credentials: credentialType,
    };
    const response = await window.fetch(url, config);
    const responseData = await response.json();
    const { data } = responseData.component || {};
    return parseFormData(data);
  } catch (error) {
    // ignore
    return {};
  }
}

// To show alert for flexible and confirm dialog when use points is checked
export const showConfirmOnFormSubmit = (
  event: React.SyntheticEvent,
  roomsCount: string,
  usePointsValue: boolean | undefined,
  usePointsMessage: string,
  flexibledateValue: string | undefined
): void => {
  if (parseInt(roomsCount, 10) > 3 && flexibledateValue) {
    event.preventDefault();
    return;
  }
  if (parseInt(roomsCount, 10) > 4 && usePointsValue && !window.confirm(usePointsMessage)) {
    // To show confirm for use points on submit
    event.preventDefault();
  }
};

async function validateFindHotelRequest(
  queryParams: any,
  publishedData: any,
  NEXT_PUBLIC_PRE_PROCESSING_URL: string,
  interceptorResponse: any,
  preHeader: any
) {
  try {
    // const preHeader: Record<string, string> = {
    //   'Content-Type': 'application/json',
    //   Cookie: `sessionID=${sessionToken}`,
    //   'Access-Control-Allow-Origin': '*',
    // };
    const preprocessorURL = `${NEXT_PUBLIC_PRE_PROCESSING_URL}?${queryParams}`;
    const updatedPreData = await axios.post(preprocessorURL, interceptorResponse, {
      headers: preHeader,
    });
    publishedData['updatedSessionData'] = updatedPreData?.data?.sessionData;
    eventUtil.dispatch('updateShopPageFormValues', publishedData);
  } catch (error: any) {
    if (error?.response?.headers?.location) {
      Router.push(error?.response?.headers?.location);
      if (sessionStorage.getItem('map_view')) {
        sessionStorage.setItem('map_view', 'false');
      }
    } else {
      throw error;
    }
  }
}

export function formFindHotelsQuery(
  searchFormRef: any,
  headersData: any,
  currentLocale: string,
  localDateFormat?: string
): string {
  const searchFormParams: URLSearchParams = new URLSearchParams(
    new FormData(searchFormRef?.current as HTMLFormElement) as any
  );
  const params = new URLSearchParams(window.location.search);

  // reset to default currency when click on update search
  if (params?.has('currency')) {
    params.set('currency', 'default');
  }

  searchFormParams.forEach((value, key) => {
    /*
      If the value of any query parameter is not available,
      then remove the query parameter from the search URL and /validate/findHotels request URL.
    */
    if (value?.trim() === '' || value === null || value === undefined) {
      params.delete(key);
    } else {
      params.set(key, value);
    }
  });
  if (!searchFormParams.has('useRewardsPoints')) {
    params.delete('useRewardsPoints');
  }
  formatDateByLocale(currentLocale, params, localDateFormat);
  // commented code as it might be required in future
  // if (
  //   params.has('destinationAddress.region') &&
  //   searchFormParams.has('destinationAddress.destination') &&
  //   searchFormParams.get('destinationAddress.destination') !== searchFormParams.get('destinationAddress.region')
  // ) {
  //   params.delete('destinationAddress.region');
  // }

  window.history.replaceState({}, headersData?.referer, decodeURIComponent(`?${params}`));
  const queryParams: string = params?.toString();
  return queryParams;
}
/**
 * Function to convert date as per locale
 * @param {string}  currentLocale pass the current locale
 * @param {any}  params pass the params received
 */
export const formatDateByLocale = (currentLocale: string, params: any, localDateFormat?: string) => {
  switch (currentLocale?.replace('_', '-')) {
    case GB_LOCALE_PATTERN_HYPHEN:
    case FR_LOCALE:
    case IT_LOCALE:
    case ES_LOCALE:
    case PT_BR_LOCALE:
      if (params.has('fromDate')) {
        params.set('fromDate', moment(params.get('fromDate')).format(DATE_MONTH_YEAR_FORMAT));
      }
      if (params.has('toDate')) {
        params.set('toDate', moment(params.get('toDate')).format(DATE_MONTH_YEAR_FORMAT));
      }
      break;
    case JP_LOCALE:
      if (params.has('fromDate')) {
        params.set('fromDate', moment(params.get('fromDate')).format(YEAR_MON_DATE_FORMAT));
      }
      if (params.has('toDate')) {
        params.set('toDate', moment(params.get('toDate')).format(YEAR_MON_DATE_FORMAT));
      }
      break;
    case KR_LOCALE:
      if (params.has('fromDate')) {
        params.set('fromDate', moment(params.get('fromDate')).format(YEAR_MON_DATE_DOTTED_FORMAT));
      }
      if (params.has('toDate')) {
        params.set('toDate', moment(params.get('toDate')).format(YEAR_MON_DATE_DOTTED_FORMAT));
      }
      break;
    case RU_LOCALE:
    case DE_LOCALE:
      if (params.has('fromDate')) {
        params.set('fromDate', moment(params.get('fromDate')).format(DATE_DOTTED_FORMAT));
      }
      if (params.has('toDate')) {
        params.set('toDate', moment(params.get('toDate')).format(DATE_DOTTED_FORMAT));
      }
      break;
    case LOCALE_ZH_CN:
    case TW_LOCALE:
      if (params.has('fromDate')) {
        params.set('fromDate', moment(params.get('fromDate')).format(DATE_FORMAT_YEAR_MONTH_DAY));
      }
      if (params.has('toDate')) {
        params.set('toDate', moment(params.get('toDate')).format(DATE_FORMAT_YEAR_MONTH_DAY));
      }
      break;
    default:
      if (localDateFormat !== '' && localDateFormat !== undefined && localDateFormat !== 'NA') {
        if (params.has('fromDate')) {
          params.set('fromDate', moment(params.get('fromDate')).format(localDateFormat));
        }
        if (params.has('toDate')) {
          params.set('toDate', moment(params.get('toDate')).format(localDateFormat));
        }
      }
      break;
  }
};

//to check if we have result with user searchQuery
export const SearchHandlerForUXLCAll = async (
  formValues?: StoreValuesType | undefined,
  isFlexibleSearch?: boolean,
  nightCount?: number,
  isAutoSuggestSelected?: boolean,
  isRecentSearchSelected?: boolean,
  searchFormRef?: any,
  NEXT_PUBLIC_PRE_PROCESSING_URL?: string,
  sessionData?: any,
  interceptorResponse?: any,
  preHeader?: any,
  currentLocale?: string,
  headersData?: any,
  localDateFormat?: string
) => {
  //search page handler,
  const datesValues = formValues?.[DATES] ?? {};
  const { fromDate, toDate } = datesValues;
  const roomValues = formValues?.[ROOMS_AND_GUESTS];
  const rates = formValues?.[SPECIAL_RATES];
  const point = formValues?.[USE_POINTS];
  let searchWithKey = {};

  const params = new URLSearchParams(window.location.search);
  if (params.has('unsuccessfulSell')) {
    params.delete('unsuccessfulSell');
    const searchCounterValue = sessionStorage.getItem('updateSearchCounter');
    window.history.replaceState({}, headersData?.referer, decodeURIComponent(`?${params}#/${searchCounterValue}/`));
  }

  if (formValues?.[DESTINATION_HIDDEN_FIELDS]?.latitude && formValues?.[DESTINATION_HIDDEN_FIELDS]?.longitude) {
    searchWithKey = {
      latitude: formValues?.[DESTINATION_HIDDEN_FIELDS]?.latitude,
      longitude: formValues?.[DESTINATION_HIDDEN_FIELDS]?.longitude,
      destination: null,
    };
  } else {
    searchWithKey = {
      destination: formValues?.[DESTINATION]?.displayText,
      latitude: null,
      longitude: null,
    };
  }

  // FOR EEO enabled users & strikethrough story
  const EEOMemberLevel =
    sessionData?.AriesCommon?.memState === 'authenticated' && sessionData.memberLevel
      ? EEO_MEMBER_CORP_CODE[sessionData.memberLevel]
      : sessionData?.AriesCommon?.memState === 'remembered' && sessionData?.AriesSearch?.rememberedMemberLevel
      ? EEO_MEMBER_CORP_CODE[sessionData?.AriesSearch?.rememberedMemberLevel]
      : null;

  let ratesType: Array<{ type: string | undefined; value: string | undefined } | []> = [];
  switch (rates?.clusterCode) {
    case 's9r':
      ratesType = [
        {
          type: 'CLUSTER',
          value: rates?.clusterCode,
        },
      ];
      break;
    case 'S9R':
      ratesType = [
        {
          type: 'CLUSTER',
          value: rates?.clusterCode,
        },
      ];
      break;
    case 'corp':
      // Check if it is Rewards Redemption flow based on the cluster code that is entered
      if (REWARDS_REDEMPTION_CLUSTER_CODES.includes(rates?.corporateCode?.toLocaleUpperCase())) {
        ratesType = [
          {
            type: 'CLUSTER',
            value: 'MRW',
          },
          {
            type: 'CLUSTER',
            value: 'P17',
          },
          {
            type: 'STANDARD',
            value: '',
          },
        ];
      } else {
        ratesType = [
          {
            type: 'CLUSTER',
            value: rates?.corporateCode,
          },
        ];
      }
      break;
    case 'gov':
      ratesType = [
        {
          type: 'GOV',
          value: rates?.clusterCode,
        },
      ];
      break;
    case 'aaa':
      ratesType = [
        {
          type: 'AAA',
          value: rates?.clusterCode,
        },
      ];
      break;
    default:
      ratesType = [
        {
          type: 'STANDARD',
          value: '',
        },
      ];
      break;
  }

  // for strikethorugh story, EEO members may get different rates based on there level.
  if (EEOMemberLevel && rates?.clusterCode === 'none') {
    ratesType = [
      {
        type: 'STANDARD',
        value: '',
      },
      {
        type: 'CLUSTER',
        value: EEOMemberLevel,
      },
    ];
  }

  if (point?.value) {
    ratesType = [
      {
        type: 'CLUSTER',
        value: 'MRW', // hardcode the value to get the points on the search result
      },
      {
        type: 'STANDARD',
        value: '',
      },
      {
        type: 'CLUSTER',
        value: 'P17',
      },
    ];
  }

  const childrenAgesInNumber: Array<number> = [];
  typeof roomValues?.childrenAges == 'object'
    ? roomValues?.childrenAges?.forEach((age: string) => {
        childrenAgesInNumber.push(parseInt(age));
      })
    : roomValues?.childrenAges?.split(',')?.forEach((age: string) => {
        childrenAgesInNumber.push(parseInt(age));
      });
  const adultCount = roomValues?.adultsCount ? roomValues?.adultsCount : 0;
  const childCount = roomValues?.childrenCount ? roomValues?.childrenCount : 0;
  /*
  retrieving domain and using it to enable mandatory fee
  */
  const isMandatoryFeeApplicable = currentLocale
    ? config?.ALLOWED_DOMAINS.includes(currentLocale) && !point?.value
    : false;
  const searchObj = {
    ...searchWithKey,
    options: {
      startDate: getDateFormatted(fromDate, DATE_FORMAT_YEAR_MONTH_DAY),
      endDate: getDateFormatted(toDate, DATE_FORMAT_YEAR_MONTH_DAY),
      childAges: childrenAgesInNumber,
      rateRequestTypes: ratesType,
      numberInParty: adultCount + childCount,
      quantity: isNaN(parseInt(roomValues?.roomsCount ?? '')) ? null : parseInt(roomValues?.roomsCount ?? ''),
      includeMandatoryFees: isMandatoryFeeApplicable ? true : false,
    },
  };

  const flexibleSearchObj = {
    ...searchWithKey,
    options: {
      numberInParty: adultCount + childCount,
      numberOfGuestRooms: isNaN(parseInt(roomValues?.roomsCount ?? '')) ? null : parseInt(roomValues?.roomsCount ?? ''),
      childAges: childrenAgesInNumber,
    },
  };

  const publishedData = {
    searchObj: isFlexibleSearch ? flexibleSearchObj : searchObj,
    destinationHiddenData: formValues?.[DESTINATION_HIDDEN_FIELDS],
    usePoints: point?.value,
    isFlexibleDateSearch: isFlexibleSearch,
    childCount: childCount,
    lengthOfStay: isFlexibleSearch ? nightCount : 1,
    formValues: formValues,
    isAutoSuggestSelected: isAutoSuggestSelected,
    isRecentSearchSelected: isRecentSearchSelected,
  };
  eventUtil.dispatch('displayLoader', {});
  if (process.env['IS_LOCAL_DEV'] === 'false') {
    const queryParams = formFindHotelsQuery(searchFormRef, headersData, currentLocale ?? 'en-US', localDateFormat);
    await validateFindHotelRequest(
      queryParams,
      publishedData,
      NEXT_PUBLIC_PRE_PROCESSING_URL as string,
      interceptorResponse,
      preHeader
    );
  } else {
    eventUtil.dispatch('updateShopPageFormValues', publishedData);
  }
};
//to fetch data for flexible Search
// export const FlexibleSearchHandlerForUXLCAll = (formValues?: StoreValuesType | undefined): void => {
//   //search page handler,

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDestinationText = (searchCriteria: any) => {
  const { destinationAddressMainText, destinationAddressSecondaryText, address, airportCode, poiName } = searchCriteria;
  if (address?.destination) {
    return address?.destination;
  } else if (destinationAddressMainText && destinationAddressSecondaryText) {
    return `${destinationAddressMainText}, ${destinationAddressSecondaryText}`;
  } else if (airportCode && poiName) {
    return `${airportCode}, ${poiName}`;
  } else {
    return ' ';
  }
};

/**
 * Method to check hotel list params in the url
 * @returns boolean
 */
export function enableHotelList(): boolean {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    const hasHotelsParam =
      params?.has('searchType') &&
      (params?.get('searchType') === 'MiniStoresHotelList' || params?.get('searchType') === 'HotelList');
    const hasHotelListParam = params?.has('hotelList') && params?.getAll('hotelList')?.length > 0;

    if (hasHotelsParam && hasHotelListParam) return true;
    else return false;
  } else {
    return false;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function flattenDataFromSession(data: any, AdfPage: any): any {
  const { AriesSearch } = data;
  let sessionObj = {};
  if (!AriesSearch) {
    return sessionObj;
  }
  const { searchCriteria } = AriesSearch;
  const {
    availabilityRequestVO,
    destinationAddressPlaceId,
    keywordSearchRequestVO,
    searchType,
    address,
    destinationAddressMainText,
    destinationAddressSecondaryText,
    searchRadius,
  } = searchCriteria;
  const {
    checkInDate,
    checkOutDate,
    flexibleDate,
    lengthOfStay,
    numRooms,
    numAdultsPerRoom,
    numChildrenPerRoom,
    childrenAges,
    rewardsRedemption,
    clusterCode,
    corporateCode,
    groupCode,
  } = availabilityRequestVO;

  let specialRateCode = clusterCode;
  if (specialRateCode === 'AAA' || specialRateCode === 'GOV' || (AdfPage && specialRateCode === 'CORP')) {
    specialRateCode = specialRateCode.toLocaleLowerCase();
  }

  let latitude;
  let longitude;

  if (address?.latitude && address?.longitude) {
    latitude = address?.latitude;
    longitude = address?.longitude;
  }

  if (destinationAddressPlaceId || address?.destination || AdfPage || enableHotelList() === true) {
    sessionObj = {
      [DESTINATION]: {
        displayText: getDestinationText(searchCriteria),
        pid: destinationAddressPlaceId,
      },
      [DATES]: {
        lastDefaultDate: checkInDate,
        fromDate: checkInDate,
        toDate: checkOutDate,
        flexible: flexibleDate,
        checkInDate,
        checkOutDate,
        lengthOfStay: lengthOfStay,
      },
      [ROOMS_AND_GUESTS]: {
        roomsCount: numRooms,
        adultsCount: numAdultsPerRoom,
        childrenCount: numChildrenPerRoom,
        childrenAges: Number(numChildrenPerRoom) > 0 ? childrenAges : [],
      },
      [DESTINATION_HIDDEN_FIELDS]: {
        latitude: latitude,
        longitude: longitude,
        primaryDescription: destinationAddressMainText,
        secondaryDescription: destinationAddressSecondaryText,
        city: address?.city,
        state: address?.stateProvince,
        country: address?.country,
        radius: parseFloat(searchRadius),
        region: address?.region,
      },
      [LATITUDE_AND_LONGITUDE]: {
        latitude: latitude,
        longitude: longitude,
      },
      [USE_POINTS]: {
        value: rewardsRedemption,
      },
      [SPECIAL_RATES]: {
        displayText: AdfPage ? clusterCode?.toLocaleLowerCase() : clusterCode,
        clusterCode: AdfPage ? specialRateCode?.toLocaleLowerCase() : specialRateCode,
        corporateCode: AdfPage
          ? specialRateCode?.toLocaleLowerCase() === 'group'
            ? groupCode
            : corporateCode?.toLocaleLowerCase()
          : corporateCode,
      },
    };
  }

  if (searchType === 'Keyword') {
    sessionObj = {
      [DESTINATION]: {
        displayText: `${keywordSearchRequestVO?.keywords}`,
      },
      [DATES]: {
        flexible: true,
      },
      [USE_POINTS]: {
        value: rewardsRedemption,
      },
      [SPECIAL_RATES]: {
        displayText: clusterCode,
        clusterCode: specialRateCode,
        corporateCode: corporateCode,
      },
    };
  }

  return sessionObj;
}

export const updateRecentSearched = (formValues: StoreValuesType | undefined) => {
  /**
   * recent search item list
   */
  // Parse any JSON previously stored in allEntries
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let existingEntries: any =
    localStorage.getItem('miRecentSearch') !== null ? localStorage.getItem('miRecentSearch') : '[]';
  const fromDate = formValues?.dates?.fromDate;
  const fromDateUTCString = moment.isMoment(fromDate) ? fromDate?.format('YYYY-MM-DDTHH:mm:ss+0000') : fromDate;
  const toDate = formValues?.dates?.toDate;
  const toDateUTCString = moment.isMoment(toDate) ? toDate?.format('YYYY-MM-DDTHH:mm:ss+0000') : toDate;
  const entry = {
    label: formValues?.destination?.displayText ? `${formValues?.destination?.displayText}` : '',
    city: formValues?.destinationHiddenFields?.city ? `${formValues?.destinationHiddenFields?.city}` : '',
    poiname: '',
    state: formValues?.destinationHiddenFields?.state ? `${formValues?.destinationHiddenFields?.state}` : '',
    country: formValues?.destinationHiddenFields?.country ? `${formValues?.destinationHiddenFields?.country}` : '',
    stateprovincedisplayname: '',
    type: '',
    countryname: '',
    airportcode: '',
    value: formValues?.destination?.displayText ? `${formValues?.destination?.displayText}` : '',
    geocode:
      formValues?.destinationHiddenFields?.latitude && formValues?.destinationHiddenFields?.longitude
        ? `${formValues?.destinationHiddenFields?.latitude},${formValues?.destinationHiddenFields?.longitude}`
        : '',
    searchtype: 'recent',
    analytics: '{"location":"searchForm","sendNow":"true","description":"Recent Search"}',
    placeId: '',
    primaryDescription: '',
    secondaryDescription: formValues?.destination?.secondaryDescription
      ? `${formValues?.destination?.secondaryDescription}`
      : '',
    latitude: formValues?.destinationHiddenFields?.latitude ? `${formValues?.destinationHiddenFields?.latitude}` : '',
    longitude: formValues?.destinationHiddenFields?.longitude
      ? `${formValues?.destinationHiddenFields?.longitude}`
      : '',
    radius: formValues?.destinationHiddenFields?.radius ? `${formValues?.destinationHiddenFields?.radius}` : '',
    address: '',
    postalCode: '',
    types: '',
    website: '',
    flexible: formValues?.dates?.flexible,
    lengthOfStay:
      formValues?.dates?.lengthOfStay ?? formValues?.dates?.toDate?.diff(formValues?.dates?.fromDate, 'days'),
    fromDate: fromDateUTCString,
    toDate: toDateUTCString,
    guests:
      formValues &&
      formValues.roomsAndGuests &&
      formValues.roomsAndGuests.childrenCount &&
      formValues.roomsAndGuests.adultsCount
        ? formValues?.roomsAndGuests?.childrenCount + formValues?.roomsAndGuests?.adultsCount
        : formValues?.roomsAndGuests?.adultsCount,
    numberOfAdults: formValues?.roomsAndGuests?.adultsCount,
    numberOfChildren: formValues?.roomsAndGuests?.childrenCount,
    roomsCount: formValues?.roomsAndGuests?.roomsCount,
    childrenAges: formValues?.roomsAndGuests?.childrenAges,
  };
  existingEntries = JSON.parse(existingEntries);
  if (entry.label !== '') {
    existingEntries.push(entry);
    localStorage.setItem('miRecentSearch', JSON.stringify(existingEntries));
  }
};

export function convertMetersToMiles(num: number | undefined | null) {
  if (num) {
    return num / MILESTOMETERS;
  }
  return num;
}

export function removeLangFromUrl(url: string) {
  // Find the positions of the first two '/' characters
  const firstSlash = url.indexOf('/');
  const secondSlash = url.indexOf('/', firstSlash + 1);

  // Check if there are at least two '/' characters
  if (firstSlash !== -1 && secondSlash !== -1) {
    // Get the substring between the first and second '/'
    const middleSegment = url.substring(firstSlash + 1, secondSlash);

    // Check if the middle segment length is 2 then remove it if true
    if (middleSegment.length === 2) {
      // Construct the modified URL
      const modifiedUrl = url.substring(secondSlash);
      return modifiedUrl;
    }
  }

  // Return the original URL if no modifications were made
  return url;
}

export async function validateAvailabilityRequest(
  isFlexibleDateSearch: boolean,
  NEXT_PUBLIC_PRE_PROCESSING_ADF_URL: string,
  propertyID: string,
  currentCurrency: string,
  includeTaxesAndFees: boolean,
  interceptorResponse: any,
  preHeader: any,
  isAdfPage: boolean,
  params?: any,
  isContinueCtaClick?: boolean,
  locale?: string,
  headersData?: Record<string, any>,
  query?: Record<string, any>
) {
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const preprocessorURL =
      isFlexibleDateSearch?.toString() === 'true'
        ? `${NEXT_PUBLIC_PRE_PROCESSING_ADF_URL}?isRateCalendar=true&propertyCode=${propertyID}&isSearch=false&currency=${
            currentCurrency === 'default' ? '' : currentCurrency
          }${params ? `&${params}` : ''}`
        : `${NEXT_PUBLIC_PRE_PROCESSING_ADF_URL}?isRateCalendar=false&propertyCode=${propertyID}&isSearch=false${
            isAdfPage ? '' : `&currency=${currentCurrency === 'default' ? '' : currentCurrency}`
          }&showFullPrice=${includeTaxesAndFees}${params ? `&${params}` : ''}`;
    const response = await axios.post(preprocessorURL, interceptorResponse, {
      headers: {
        'Accept-language': locale ?? hardCodedAllLocale.en,
        ...preHeader,
        Dtt: headersData?.['dtt'] === 'true' ? 'true' : 'false',
        ...getDTTHeaders(headersData, query),
      },
    });
    const redirectSuccessUri = response?.headers?.['location'] || (response?.headers?.['Location'] as string);
    const url = new URL(redirectSuccessUri);
    const documentLocale = document?.documentElement?.lang.split(/_|-/)[0].toLowerCase();
    const finalUrl = addSubDirectoryPrefix(
      localeToRemoveFromURL.includes(documentLocale) ? removeLangFromUrl(url.pathname) : url.pathname
    );
    if (isAdfPage && isFlexibleDateSearch && !isContinueCtaClick) {
      const searchCounterValue = sessionStorage.getItem('updateAdfSearchCounter');
      window.history.replaceState(
        {},
        '',
        `${url.origin}${finalUrl}?propertyCode=${propertyID}&isSearch=true&currency=${
          currentCurrency === 'default' ? '' : currentCurrency
        }${params ? `&${params}` : ''}#/${searchCounterValue}/`
      );
    }

    const isNavigatingToAdf = redirectSuccessUri && redirectSuccessUri?.indexOf('/search/availabilityCalendar') === -1;

    if (isNavigatingToAdf) {
      window.location.href = `${url.origin}${finalUrl}`;
    }

    return response;
  } catch (error: any) {
    const redirectUri = error?.response?.headers?.location || error?.response?.headers?.['Location'];
    const isNotNavigatingToAdf = redirectUri && redirectUri?.indexOf('/search/availabilityCalendar') === -1;
    if (isNotNavigatingToAdf) {
      window.location.href = redirectUri;
    } else {
      throw error;
    }
    return;
  }
}

export const formatDatetoLocale = (sessionData: any, currentLocale: string, cacheDataPresent: boolean) => {
  const checkInDate = cacheDataPresent
    ? sessionData?.cacheData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkInDate
    : sessionData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkInDate;
  const checkOutDate = cacheDataPresent
    ? sessionData?.cacheData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkOutDate
    : sessionData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkOutDate;

  if (typeof window !== 'undefined' && (currentLocale === 'en_GB' || currentLocale === 'en-GB')) {
    if (cacheDataPresent) {
      if (sessionData?.cacheData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO) {
        sessionData.cacheData.data.AriesSearch.searchCriteria.availabilityRequestVO.checkInDate = new Date(
          moment(moment(checkInDate).format('YYYY-DD-MM')).format('YYYY-MM-DD')
        ).toISOString();
        sessionData.cacheData.data.AriesSearch.searchCriteria.availabilityRequestVO.checkOutDate = new Date(
          moment(moment(checkOutDate).format('YYYY-DD-MM')).format('YYYY-MM-DD')
        ).toISOString();
      }
      return sessionData;
    } else {
      if (sessionData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO) {
        sessionData.data.AriesSearch.searchCriteria.availabilityRequestVO.checkInDate = new Date(
          moment(moment(checkInDate).format('YYYY-DD-MM')).format('YYYY-MM-DD')
        ).toISOString();
        sessionData.data.AriesSearch.searchCriteria.availabilityRequestVO.checkOutDate = new Date(
          moment(moment(checkOutDate).format('YYYY-DD-MM')).format('YYYY-MM-DD')
        ).toISOString();
      }
      return sessionData;
    }
  } else {
    return sessionData;
  }
};
