import styled from 'styled-components';
import { toRem, theme } from '../../styles';

export const StyledDropdown = styled.div`
  label {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: ${toRem(1.3)};
    font-size: ${theme.fonts['fontXs']};
    color: ${theme.colors['dark-gray-3']};
    line-height: ${theme.fonts['lineHeightXs']};
    font-weight: ${theme.fonts['fontWeightMedium']};
  }

  .select-box {
    position: relative;

    select {
      width: 100%;
      border: none;
      display: flex;
      cursor: pointer;
      height: ${toRem(43)};
      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 1rem center;
      &:dir(rtl) {
        background-position: 1rem center;
      }
      background-size: 1rem;
      background-color: transparent;
      font-size: ${theme.fonts['fontL']};
      line-height: ${theme.fonts['lineHeightXl']};
      color: ${theme.colors['base-10']};
      padding: ${toRem(5)} 0 ${toRem(13.5)} 0;
      border-bottom: ${theme.colors['dark-gray-3']} solid 2px;
      &:focus {
        border-color: ${theme.colors['base-10']};
      }
      &.is-error {
        border-color: var(--t-form-error-color);
      }

      option {
        background-color: transparent;
        font-size: ${theme.fonts['fontL']};
        line-height: ${theme.fonts['lineHeightXl']};
        color: ${theme.colors['dark-gray-3']};
      }
    }
  }

  fieldset:focus-within {
    border: ${theme.colors['dark-gray-2']} solid ${toRem(2)};
  }

  .outlined {
    border: ${theme.colors['dark-gray-3']} solid ${toRem(1)};
    border-radius: ${toRem(4)};
    position: relative;
    margin-top: ${toRem(12)};

    .select-box {
      select {
        border-bottom: 0;
        height: ${toRem(48)};
        padding: ${toRem(12)} 0 ${toRem(12)} ${toRem(16)};
        font-size: ${theme.fonts['fontM']};
        line-height: ${theme.fonts['lineHeightXs']};
        &:dir(rtl) {
          padding-inline-start: ${toRem(8)};
        }
      }
      .icon-arrow-down {
        top: ${toRem(18)};
      }
    }

    &.is-error {
      border: var(--t-form-error-color) solid ${toRem(1)} !important;
      .outlined-label {
        color: var(--t-form-error-color);
      }
    }

    &:focus-within > .outlined-label {
      color: ${theme.colors['base-10']};
    }
  }

  .outlined-label {
    width: auto;
    margin: 0;
    padding: ${toRem(6)};
    position: absolute;
    top: ${toRem(-15)};
    inset-inline-start: ${toRem(12)};
    background-color: ${theme.colors.white};
    font-size: ${theme.fonts['fontS']};
    color: ${theme.colors['dark-gray-3']};
    line-height: ${theme.fonts['lineHeightXs']};
    font-weight: ${theme.fonts['fontWeightMedium']};
  }

  .no-border {
    border: 0 !important;
  }

  .read-only-field {
    border: ${theme.colors['light-gray-6']} solid ${toRem(1)};
    .outlined-label {
      color: ${theme.colors['light-gray-6']};
    }
    .select-box {
      select {
        color: ${theme.colors['dark-gray-3']};
        pointer-events: none;
      }
    }
  }
`;

export const StyledError = styled.p`
  margin: ${toRem(4)} 0 0;
  color: ${theme.colors.monza};
  font-size: ${theme.fonts['fontXs']};
  line-height: ${theme.fonts['lineHeightXs']};

  &.icon-information::before {
    margin-right: ${toRem(9.3)};
  }
  &.information {
    margin-left: ${toRem(16)};
  }
`;
