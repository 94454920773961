import React, { FC } from 'react';
import clsx from 'clsx';

import { VariableColumnContainerProps } from './VariableColumnContainer.types';
import { StyledVariableColumn } from './VariableColumnContainer.styles';

import { Eyebrow } from '../../atoms/Eyebrow';
import { headingType, size } from '../../utils/enums/enums';
import { Button } from '../../atoms/Button';
import { RichText } from '../RichText';
import { Heading } from '../../atoms/Heading';

export const VariableColumnContainer: FC<VariableColumnContainerProps> = props => {
  const {
    componentId,
    subHeader,
    styleclass,
    eyebrow,
    trackingProperties,
    children,
    ctaLabel,
    header,
    ctaLink,
    openInNewTab,
    ctaType,
    headerTag,
    contentAlignment,
    reverseStacking,
    headerFontSize = 't-title-m' as size.medium,
    subFontSize = 't-subtitle-l' as size.large,
    descFontSize = 't-font-m' as size.medium,
    subHeaderTag,
    descriptionText,
    mobileColumns,
  } = props;

  const isColumnStack = mobileColumns === '1';

  const isLink = ctaType === 'tertiaryLink';

  // const DynamicHeader = ({
  //   headerTag = 'h2' as tags.h2,
  //   content,
  //   className,
  // }: {
  //   headerTag?: tags;
  //   content: string;
  //   className: string;
  // }) => {
  //   const Component = headerTag;
  //   return <Component dangerouslySetInnerHTML={{ __html: content }} className={className} />;
  // };

  const headerText = () =>
    // header && <DynamicHeader headerTag={headerTag} className={clsx('mt-3', headerFontSize, 'mb-0')} content={header} />;
    header && (
      <Heading
        customClass={clsx('mt-3', headerFontSize, 'mb-0')}
        variation={headingType.title}
        fontSize={headerFontSize}
        element={headerTag}
        dangerouslySet={header}
      />
    );

  const subHeaderText = () =>
    subHeader && (
      <Heading
        customClass={clsx(!header ? 'mt-3' : '', subFontSize, 'mb-0')}
        variation={headingType.subtitle}
        fontSize={subFontSize}
        element={subHeaderTag}
        dangerouslySet={subHeader}
      />
    );

  const descriptionBlock = () =>
    descriptionText && (
      <RichText
        text={descriptionText}
        customClass={clsx(!subHeader && !header ? 'mt-3' : '', descFontSize)}
        componentId={componentId + '-DescriptionText'}
      />
    );

  const cta = () => {
    return (
      ctaLabel && (
        <Button
          href={ctaLink}
          isLink={isLink}
          target={openInNewTab ? '_blank' : '_self'}
          callback={!isLink ? buttonCallback : undefined}
          className={[
            ctaType === 'primaryButton' ? 'm-button-primary' : '',
            ctaType === 'secondaryButton' ? 'm-button-secondary' : '',
            isLink ? 'm-link-tertiary-button' : '',
            openInNewTab
              ? ctaType === 'primaryButton' || ctaType === 'secondaryButton'
                ? 'm-button-external'
                : 'm-link-tertiary-button-external'
              : '',
            !header && !subHeader && !descriptionText ? 'mt-3' : '',
          ]}
          custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.description}|${
            openInNewTab ? 'external' : 'internal'
          }`}
          buttonCopy={ctaLabel}
          isTextBeforeChildren={false}
        />
      )
    );
  };

  const variableColumnHeader = () => {
    const hasContent = eyebrow || header || subHeader || descriptionText || ctaLabel;

    return (
      hasContent && (
        <div
          className={clsx(
            'variable_column_header mx-2',
            contentAlignment === 'center' ? 'align-items-center' : '',
            contentAlignment === 'right' ? 'align-items-end' : ''
          )}
        >
          {eyebrow && eyebrowContent()}
          {header && headerText()}
          {subHeader && subHeaderText()}
          {descriptionText && descriptionBlock()}
          {ctaLabel && cta()}
        </div>
      )
    );
  };

  const eyebrowContent = () => eyebrow && <Eyebrow text={eyebrow} />;

  const buttonCallback = () => {
    window.open(ctaLink, openInNewTab ? '_blank' : '_self');
  };

  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};

  return (
    <StyledVariableColumn
      data-component-name="m-ui-library-VariableColumnContainer"
      data-testid="ui-library-VariableColumnContainer"
      id={componentId}
      className={clsx(styleclass, 'vcc-container')}
      {...customAttributes}
    >
      <div className={clsx('container-sm p-0')}>
        {variableColumnHeader()}
        <ul
          className={clsx(
            'd-flex flex-wrap mb-0',
            reverseStacking && isColumnStack
              ? 'reverse-stack-column'
              : reverseStacking && !isColumnStack
              ? 'reverse-stack'
              : ''
          )}
        >
          {children}
        </ul>
      </div>
    </StyledVariableColumn>
  );
};
