import styled from 'styled-components';
import { toRem, baseVariables, contextualVariable } from '../../styles';
import { CardVerticalVariations } from './CardVertical.types';

export const StyledCardVertical = styled.div<{ cardVerticalVariation: CardVerticalVariations }>`
  height: 100%;
  width: ${props => (props.cardVerticalVariation === CardVerticalVariations.Standard ? 'unset' : 'fit-content')};
  box-shadow: ${baseVariables.shadows['shadowBlur01']};
  background-color: ${contextualVariable['brandBgColor']};
  border-radius: ${toRem(14)};
  box-sizing: border-box;
  .card-vertical {
    position: relative;
    border-radius: ${toRem(14)};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    max-width: 100%;
    width: fit-content;
    height: 100%;
    .image {
      max-width: 100%;
    }
    &__body {
      padding: ${props =>
        props.cardVerticalVariation === CardVerticalVariations.Standard
          ? `${toRem(24)}`
          : `${toRem(24)} ${toRem(32)} ${toRem(32)} ${toRem(32)}`};
      display: inline-block;
      width: 100%;
      gap: ${toRem(7)};
    }
    &__tall16x9 {
      .card-vertical_heading {
        margin: ${toRem(8)} auto ${toRem(16)} auto;
      }
      .card-vertical_eyebrow {
        overflow: hidden;
      }
      .card-vertical-detailLabel {
        padding: ${toRem(0)} ${toRem(16)};
        border-top: ${toRem(0.75)} solid ${baseVariables.color['neutral30']};
        border-bottom: ${toRem(0.75)} solid ${baseVariables.color['neutral30']};
        display: grid;
        grid-auto-flow: column;
        width: fit-content;
        margin: auto;
        .card-vertical-iconfont {
          text-align: left;
          margin: auto;
        }
      }
      .card-vertical_detail {
        display: grid;
      }
      .cv-tall16x9-button {
        width: fit-content;
        margin: auto;
      }
      .card-vertical-description {
        margin: ${props =>
          props.cardVerticalVariation === CardVerticalVariations.Tall16x9
            ? `${toRem(16)} auto ${toRem(16)} auto`
            : `${toRem(16)} 0`};
        p {
          margin-bottom: ${toRem(0)};
        }
      }
      .text-container {
        max-width: ${toRem(300)};
        margin: auto;
        flex-grow: 1;
      }
    }

    &_overlay {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem;
      top: 0;
      right: 0;
      left: 0;
    }
    &_badge {
      white-space: nowrap;
      overflow: hidden;
      margin-right: ${toRem(40)};
      height: ${toRem(24)};
      padding: 0 ${toRem(8)};
    }
    &_socialicon {
      padding: ${toRem(4)};
      border-radius: ${toRem(4)};
      position: inherit;
      right: 0;
      top: 0;
    }
    .text-center-card-vertical {
      position: relative;
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
      max-width: ${props =>
        props.cardVerticalVariation === CardVerticalVariations.Tall1x1
          ? `${toRem(268)}`
          : props.cardVerticalVariation === CardVerticalVariations.Tall16x9
          ? `${toRem(440)}`
          : `${toRem(460)}`};
      @media ${baseVariables.mediaQuery.sm} {
        max-width: ${props =>
          props.cardVerticalVariation === CardVerticalVariations.Tall1x1
            ? `${toRem(268)}`
            : props.cardVerticalVariation === CardVerticalVariations.Tall16x9
            ? `${toRem(280)}`
            : `${toRem(296)}`};
      }
      @media ${baseVariables.mediaQuery.md} {
        max-width: ${props =>
          props.cardVerticalVariation === CardVerticalVariations.Tall1x1
            ? `${toRem(280)}`
            : props.cardVerticalVariation === CardVerticalVariations.Tall16x9
            ? `${toRem(280)}`
            : `${toRem(296)}`};
      }
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${props =>
          props.cardVerticalVariation === CardVerticalVariations.Tall1x1
            ? `${toRem(300)}`
            : props.cardVerticalVariation === CardVerticalVariations.Tall16x9
            ? `${toRem(300)}`
            : `${toRem(296)}`};
      }
    }
    &_chevron {
      display: flex;
      position: relative;
      flex-direction: column;
      max-width: fit-content;
      &[class*='icon-arrow-']:before {
        position: absolute;
        right: ${toRem(24)};
        bottom: ${toRem(24)};
        z-index: 1;
      }
    }
    .seperator {
      display: inline-block;
      height: ${toRem(1)};
      width: ${toRem(72)};
      margin-bottom: ${toRem(8)};
    }
    .truncate-text,
    .truncate-text p {
      text-align: ${props => (props.cardVerticalVariation === CardVerticalVariations.Standard ? 'left' : 'center')};
    }
    .description-height {
      overflow: hidden;
      margin-bottom: ${toRem(20)};
    }
    .description {
      padding: 0 0 ${toRem(20)};
      p {
        margin: ${toRem(0)};
      }
    }
    &_description {
      overflow: hidden;
      p {
        margin: ${toRem(0)};
      }
    }
    &_detail {
      display: flex;
      width: 100%;
    }
    .no-description {
      margin-bottom: ${toRem(7)};
    }
    &_heading {
      margin-right: ${props =>
        props.cardVerticalVariation === CardVerticalVariations.Standard ? `${toRem(24)}` : '0'};
    }
    .m-link-action {
      line-height: ${toRem(22)};
    }
  }
  .glide__slides & {
    white-space: normal;
  }
`;

export const StyledCardVerticalContainer = styled.div<{ cardVerticalVariation: CardVerticalVariations }>`
  width: ${props => (props.cardVerticalVariation === CardVerticalVariations.Standard ? 'auto' : 'fit-content')};
  height: 100%;
`;
