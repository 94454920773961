import { isCNLocale } from '@marriott/shared/mi-helper-utils';
import { constants } from '../constants';

/**
 * Custom hook to unify redirection links wherever possible.
 *
 * @param {string} brandCode Brand code of property to determine applicable redirection rule
 * @param {string} marshaCode Marsha code (propertyID) of the property
 * @param {string} propertyName Property name to construct redirection URL
 * @param {string} redirectionType Custom variable to determine redirection type
 * @param {string} locale Current locale of the property (optional arg)
 * @param {string} location Property location that is appended to the Bulgari URL
 * @param {string} redirectionDomain The domain to be redirected to. Can be left blank if relative URL
 */

export function useRedirectionRules(
  brandCode: string,
  marshaCode: string,
  propertyName: string,
  redirectionType: string,
  locale?: string,
  location?: string,
  redirectionDomain?: string
) {
  const {
    HWSLandingPageRedirectionBrands,
    viewHotelWebsiteLink,
    hotelsSublink,
    reviewsSubLink,
    reviewsRedirectionVarValue,
    HWSRedirectionVarValue,
  } = constants;
  let redirectionURL = '';
  if (redirectionType === reviewsRedirectionVarValue) {
    if (HWSLandingPageRedirectionBrands.includes(brandCode)) {
      redirectionURL =
        viewHotelWebsiteLink +
        marshaCode +
        '-' +
        propertyName
          ?.replace('-', ' ')
          ?.replace(/([~!@#$%^&*()_+=`{}[\]|\\:;'<>,./? ])+/g, '-')
          ?.replace(/^(-)+|(-)+$/g, '')
          ?.toLowerCase();
    } else {
      redirectionURL =
        '/' +
        locale?.replace('_', '-') +
        hotelsSublink +
        marshaCode +
        '-' +
        propertyName
          ?.replace('-', ' ')
          ?.replace(/([~!@#$%^&*()_+=`{}[\]|\\:;'<>,./? ])+/g, '-')
          ?.replace(/^(-)+|(-)+$/g, '')
          ?.toLowerCase() +
        reviewsSubLink;
    }
  } else if (redirectionType === HWSRedirectionVarValue) {
    if (brandCode.toLowerCase() === 'bg') {
      locale = locale?.toLowerCase();
      const URLlocale = isCNLocale(locale)
        ? locale
        : `${locale?.split('-')?.[0]}_${locale?.split('-')?.[1]?.toUpperCase()}`;
      redirectionURL = (redirectionDomain ?? '') + '/' + URLlocale + '/' + location?.toLowerCase()?.replace(' ', '-');
    }
  }
  return redirectionURL;
}
