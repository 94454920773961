import { useSwiper } from 'swiper/react';
import { Button } from '@marriott/mi-ui-library';

import { PostcardContainerProps } from './PostcardContainer.types';
import { getClickTrackValue } from '../../utils/getClickTrackValue';
import { POSTCARD_CONSTANTS } from '../../utils';

export const PostcardContainerButtons = (props: PostcardContainerProps) => {
  const { trackingProperties } = props;
  const trackingPropertiesObject = { ...trackingProperties, trackingDescription: '' };
  const swiper = useSwiper();
  return (
    <div className="d-flex flex-row carousel-arrow-container">
      <Button
        trackingProperties={trackingProperties}
        className="carousel-left-arrow"
        data-glide-dir=">"
        aria-label="Prev"
        callback={() => swiper.slidePrev()}
        custom_click_track_value={getClickTrackValue({
          trackingProperties: trackingPropertiesObject,
          fallbacks: {
            position: POSTCARD_CONSTANTS.POSITION,
            description: POSTCARD_CONSTANTS.LEFT_ARROW_BELOW,
          },
        })}
      >
        <span className="icon-arrow-left"></span>
      </Button>
      <Button
        trackingProperties={trackingProperties}
        className="carousel-right-arrow"
        data-glide-dir=">"
        aria-label="Next"
        callback={() => swiper.slideNext()}
        custom_click_track_value={getClickTrackValue({
          trackingProperties: trackingPropertiesObject,
          fallbacks: {
            position: POSTCARD_CONSTANTS.POSITION,
            description: POSTCARD_CONSTANTS.RIGHT_ARROW_BELOW,
          },
        })}
      >
        <span className="icon-arrow-right"></span>
      </Button>
    </div>
  );
};
